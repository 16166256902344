import { RESET_DEFAULTS, SUM_HISTORICAL_BALANCES } from "../constants/actions";

const initialState = {};

// {
//     [timestamp]: balance
// }

const historicalSumBalancesReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case SUM_HISTORICAL_BALANCES:
      return action.data;
    case RESET_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default historicalSumBalancesReducer;
