import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Box,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import { DRAWER_WIDTH } from "../constants/properties";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  ROUTE_MAP,
  SETTINGS,
  DOCUMENTS_STATEMENTS,
  DOCUMENTS_TAX,
  DOCUMENTS_ONBOARDING,
  DOCUMENTS_INVESTOR_UPDATES,
  DOCUMENTS,
  LOG_OUT,
} from "../constants/routes";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Skeleton from "@material-ui/lab/Skeleton";
import { Alert } from "@material-ui/lab";
import { setShowFundHoldingsDisclaimer } from "../actions/funds";

const useStyles = makeStyles((theme) => ({
  alertButton: {
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.white.main,
    boxShadow: "none",
    borderBottom: "1px solid #999",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarAlert: {
    top: 64,
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  icon: {
    height: 25,
    "&.account": {
      color: "#999",
      marginLeft: 10,
    },
  },
  logo: {
    height: 45,
    width: 45,
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  name: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
  },
  smallToolbar: {
    justifyContent: "space-between",
  },
  tab: {
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    fontFamily: "Gill Sans Std",
    fontWeight: 400,
    letterSpacing: "0.5px",
  },
  tabs: {
    // borderBottom: `1px solid ${theme.palette.white.main}`,
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}));

function SmallScreenAndSmallerNavbar({ location, history }) {
  const classes = useStyles();
  const isDocumentsSection = location.pathname.includes(DOCUMENTS);
  return (
    <AppBar>
      <Toolbar className={classes.smallToolbar}>
        <Box display="flex" alignItems="center">
          <img src="/brand/icon-white.png" alt="" className={classes.logo} />
          <Typography
            component="h1"
            variant="h5"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {ROUTE_MAP[location.pathname]}
          </Typography>
        </Box>
        <AccountCircleIcon
          className={clsx(classes.icon, "account")}
          onClick={() => history.push(SETTINGS)}
        />
      </Toolbar>
      {isDocumentsSection && (
        <Tabs
          value={location.pathname}
          className={classes.tabs}
          onChange={(e, newValue) => history.push(newValue)}
          indicatorColor="primary"
        >
          <Tab
            value={DOCUMENTS_STATEMENTS}
            label="Statements"
            className={classes.tab}
          />
          <Tab value={DOCUMENTS_TAX} label="Tax" className={classes.tab} />
          <Tab
            value={DOCUMENTS_ONBOARDING}
            label="LP Docs"
            className={classes.tab}
          />
          <Tab
            value={DOCUMENTS_INVESTOR_UPDATES}
            label="Updates"
            className={classes.tab}
          />
        </Tabs>
      )}
    </AppBar>
  );
}

function MediumScreenAndLargerNavbar({
  profile,
  isSidebarOpen,
  setIsSidebarOpen,
  location,
  history,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorWidth, setAnchorWidth] = useState(100);
  const anchorRef = useRef(null);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => history.push(LOG_OUT);

  useEffect(() => {
    setAnchorWidth(anchorRef.current.offsetWidth);
  }, [profile]);

  return (
    <AppBar
      position="absolute"
      color="secondary"
      className={clsx(classes.appBar, !!isSidebarOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className={clsx(
            classes.menuButton,
            isSidebarOpen && classes.menuButtonHidden
          )}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h5"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {ROUTE_MAP[location.pathname]}
        </Typography>
        <div className={classes.name} onClick={handleOpen} ref={anchorRef}>
          {profile.firstName && profile.lastName ? (
            <React.Fragment>
              <Typography variant="h5">
                {profile.firstName} {profile.lastName}
              </Typography>
              <AccountCircleIcon className={clsx(classes.icon, "account")} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Skeleton animation="wave" height={32} width={150} />
              <Skeleton
                animation="wave"
                height={25}
                width={25}
                style={{ marginLeft: 10, borderRadius: "50%" }}
              />
            </React.Fragment>
          )}
        </div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => history.push(SETTINGS)}
            style={{ width: anchorWidth }}
          >
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout} style={{ width: anchorWidth }}>
            Log out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

function FundBalanceAlert({ isSidebarOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <AppBar
      position="absolute"
      color="secondary"
      className={clsx(
        classes.appBar,
        classes.appBarAlert,
        isSidebarOpen && classes.appBarShift
      )}
    >
      <Alert
        severity="info"
        action={
          <Button
            color="inherit"
            size="small"
            className={classes.alertButton}
            onClick={() => dispatch(setShowFundHoldingsDisclaimer(false, true))}
          >
            I Understand
          </Button>
        }
      >
        The Fund may hold some combination of cash (USD), Bitcoin (BTC), BTC
        futures, and options on BTC futures at any time. Thus, these estimated
        account values reflect the impact of the Fund’s trading strategy and are
        not solely moved by the price of BTC.
      </Alert>
    </AppBar>
  );
}

const propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  setIsSidebarOpen: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function Navbar({ isSidebarOpen, setIsSidebarOpen }) {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const isMediumScreenAndLarger = useMediaQuery(theme.breakpoints.up("md"));
  const profile = useSelector((state) => state.profile);
  const fundHoldingsDisclaimer = useSelector(
    (state) => state.fundHoldingsDisclaimer
  );

  if (isMediumScreenAndLarger) {
    return (
      <React.Fragment>
        <MediumScreenAndLargerNavbar
          profile={profile}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          location={location}
          history={history}
        />
        {fundHoldingsDisclaimer.isShowDisclaimer && (
          <FundBalanceAlert isSidebarOpen={isSidebarOpen} />
        )}
      </React.Fragment>
    );
  }
  return <SmallScreenAndSmallerNavbar location={location} history={history} />;
}

Navbar.propTypes = propTypes;
Navbar.defaultProps = defaultProps;
