import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { submitNewTransaction } from "../actions/txs";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { useSnackbar } from "notistack";
import { ERROR_SNACKBAR, SUCCESS_SNACKBAR } from "../constants/properties";
import { formatWithCommas, getErrorMessage } from "../util/helpers";
import {
  Select as FormikSelect,
  TextField as FormikTextField,
} from "formik-material-ui";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  close: {
    position: "absolute",
    right: "2%",
    top: "2%",
    cursor: "pointer",
  },
  form: {
    marginTop: theme.spacing(2),
  },
  modal: {
    backgroundColor: theme.palette.grey.light,
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    padding: theme.spacing(3),
    borderRadius: "7px",
    boxShadow: "0 2px 6px 0 rgba(8, 19, 28, .2)",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      top: "44%",
      textAlign: "center",
    },
  },
  modalSummary: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
}));

const propTypes = {
  setModal: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function NewSubscription({ setModal }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector((state) => state.user);
  const profile = useSelector((state) => state.profile);
  const funds = useSelector((state) => state.funds);
  const balances = useSelector((state) => state.balances);

  return (
    <div className={classes.modal}>
      <CloseIcon
        className={classes.close}
        onClick={() =>
          setModal({
            isOpen: false,
            component: null,
          })
        }
      />
      <Typography variant="h5">Make a New Subscription</Typography>
      <Typography className={classes.modalSummary} variant="body2">
        Once submitted, you will be taken to DocuSign to sign your subscription
        agreement.
      </Typography>
      <Formik
        initialValues={{
          fund:
            !!Object.keys(funds) && Object.keys(funds).length > 0
              ? Object.keys(funds)[0]
              : "",
          amount: "",
        }}
        validationSchema={Yup.object({
          fund: Yup.string().required("Please select a fund"),
          amount: Yup.number()
            .min(50000, "Minimum Investment is $50,000")
            .required("Required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            submitNewTransaction(
              user.id,
              values.fund,
              {
                operationType: "SUBSCRIPTION",
                amount: values.amount,
              },
              authState.accessToken.value
            )
          )
            .then((response) => {
              setSubmitting(false);
              enqueueSnackbar(
                "Thank you, we have received your request.",
                SUCCESS_SNACKBAR
              );
              setModal({
                isOpen: false,
                component: null,
              });
            })
            .catch((error) => {
              enqueueSnackbar(getErrorMessage(error), ERROR_SNACKBAR);
              setSubmitting(false);
              setModal({
                isOpen: false,
                component: null,
              });
            });

          if (!!balances.find((bal) => bal.fundId === values.fund)) {
            let baseUrl;
            if (values.fund === "004cb3674d314e7d9e08e8540b0ace4c") {
              baseUrl =
                "https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=9086719d-7426-48cb-a5ed-6baffc4118e5&env=na3-eu1&acct=6600a94a-ea35-4a26-bcac-cc836f434b53&v=2";
            }
            if (values.fund === "28c80932c0fa4b66989720cd11d669aa") {
              baseUrl =
                "https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=f7136873-8061-473d-bfb7-b9839123c5d1&env=na3&acct=6600a94a-ea35-4a26-bcac-cc836f434b53&v=2";
            }
            if (!!baseUrl) {
              window.open(
                `${baseUrl}&amount=${values.amount}&fullName=${profile.lpName}&Subscriber_Email=${profile.email}&Subscriber_UserName=${profile.lpName}&interestClass=A`,
                "_blank"
              );
            }
          }
        }}
      >
        {({ isSubmitting, values, errors, setFieldValue }) => (
          <Form className={classes.form}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>Fund</InputLabel>
              <Field
                component={FormikSelect}
                name="fund"
                label="Fund"
                variant="outlined"
              >
                {Object.entries(funds).map(([fundId, fund]) => (
                  <MenuItem value={fundId} key={fundId}>
                    {fund.name}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <Field
              component={FormikTextField}
              name="amount"
              label="Amount to Invest"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(event) =>
                setFieldValue("amount", event.target.value.replace(/\D/g, ""))
              }
              value={`$${formatWithCommas(values.amount)}`}
              helperText={errors.amount || "Minimum Investment is $50,000"}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              className={clsx(classes.submitButton, "button-gillsans")}
            >
              Initiate Subscription
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

NewSubscription.propTypes = propTypes;
NewSubscription.defaultProps = defaultProps;
