import { RECEIVE_DOCUMENTS, RESET_DEFAULTS } from "../constants/actions";

const initialState = [];

// [{
// id: String
// date: String ISO-8601
// fundId: String
// uid: String
// documentType: String
// description: String
// downloadUrl: String
// }]

const documentsReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_DOCUMENTS:
      return action.data;
    case RESET_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default documentsReducer;
