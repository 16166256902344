import { getFromCastleAPI, postToCastleAPI } from "../util/api-util";
import { RECEIVE_USER_TX_HISTORY, SUBMIT_USER_TX } from "../constants/actions";

export const getUserTxHistory = (userId, accessToken) => (dispatch) =>
  getFromCastleAPI(`accounts/${userId}/operations`, accessToken)
    .then((response) => {
      return dispatch(receiveUserTxHistory(response.data.data.operations));
    })
    .catch((error) => {
      console.log("[getUserTxHistory] error: ", error);
      throw error;
    });

export const receiveUserTxHistory = (data) => ({
  type: RECEIVE_USER_TX_HISTORY,
  data,
});

//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\\

export const submitNewTransaction = (userId, fundId, body, accessToken) => (
  dispatch
) =>
  postToCastleAPI(
    `accounts/${userId}/operations/byindex/${fundId}`,
    body,
    accessToken
  )
    .then((response) => {
      return dispatch(submitUserTx(response.data.data));
    })
    .catch((error) => {
      console.log("[submitNewTransaction] error: ", error);
      throw error;
    });

export const submitUserTx = (data) => ({
  type: SUBMIT_USER_TX,
  data,
});
