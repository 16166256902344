import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { dollarize, formatWithCommas, getErrorMessage } from "../util/helpers";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_SNACKBAR, SUCCESS_SNACKBAR } from "../constants/properties";
import { useOktaAuth } from "@okta/okta-react";
import { useSnackbar } from "notistack";
import { submitNewTransaction } from "../actions/txs";
import {
  Select as FormikSelect,
  RadioGroup as FormikRadioGroup,
  TextField as FormikTextField,
} from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  close: {
    position: "absolute",
    right: "2%",
    top: "2%",
    cursor: "pointer",
  },
  form: {
    marginTop: theme.spacing(2),
  },
  modal: {
    backgroundColor: theme.palette.grey.light,
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    padding: theme.spacing(3),
    borderRadius: "7px",
    boxShadow: "0 2px 6px 0 rgba(8, 19, 28, .2)",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  notice: {
    maxWidth: "440px",
    margin: "0 auto",
    marginTop: theme.spacing(4),
    textAlign: "center",
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
      fontSize: "12px",
    },
  },
  radio: {
    color: `${theme.palette.primary.main} !important`,
  },
  radioGroup: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  summary: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const propTypes = {
  setModal: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function NewRedemption({ setModal }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.user);
  const balances = useSelector((state) => state.balances);
  const funds = useSelector((state) => state.funds);

  return (
    <div className={classes.modal}>
      <CloseIcon
        className={classes.close}
        onClick={() =>
          setModal({
            isOpen: false,
            component: null,
          })
        }
      />
      <Typography variant="h5">Make a New Redemption</Typography>
      <Typography className={classes.summary} variant="body2">
        Please indicate whether you would like to make a partial redemption or
        redeem your entire balance. Once submitted, a Castle team member will
        reach out with next steps.
      </Typography>
      <Formik
        initialValues={{
          fund: balances[0] ? balances[0].fundId : "",
          type: "partial",
          amount: "",
        }}
        validationSchema={Yup.object({
          fund: Yup.string().required("Please select a fund"),
          amount: Yup.number().when(["type", "fund"], (type, fund, schema) =>
            !!fund && type === "partial"
              ? schema
                  .required("Required")
                  .min(1, "Cannot be less than 1")
                  .max(
                    balances.find((balFund) => balFund.fundId === fund).balance,
                    `Cannot exceed current balance of ${dollarize(
                      balances.find((balFund) => balFund.fundId === fund)
                        .balance
                    )}`
                  )
              : schema
          ),
          fullAmount: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const currentBalance = balances.find(
            (fund) => fund.fundId === values.fund
          ).balance;
          const amount = (values.type === "partial") ? values.amount : currentBalance;
          dispatch(
            submitNewTransaction(
              user.id,
              values.fund,
              {
                operationType: "WITHDRAWAL",
                amount,
              },
              authState.accessToken.value
            )
          )
            .then((response) => {
              setSubmitting(false);
              enqueueSnackbar(
                "Thank you, we have received your request.",
                SUCCESS_SNACKBAR
              );
              setModal({
                isOpen: false,
                component: null,
              });
            })
            .catch((error) => {
              enqueueSnackbar(getErrorMessage(error), ERROR_SNACKBAR);
              setSubmitting(false);
              setModal({
                isOpen: false,
                component: null,
              });
            });
        }}
      >
        {({ isSubmitting, values, errors, setFieldValue }) => (
          <Form className={classes.form}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>Fund</InputLabel>
              <Field
                component={FormikSelect}
                name="fund"
                label="Fund"
                variant="outlined"
              >
                {balances.map((balance) => (
                  <MenuItem value={balance.fundId} key={balance.fundId}>
                    {funds[balance.fundId] ? funds[balance.fundId].name : ""}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <FormControl component="fieldset" className={classes.radioGroup}>
              <Field component={FormikRadioGroup} name="type">
                <FormControlLabel
                  value="fullAmount"
                  control={
                    <Radio disabled={isSubmitting} className={classes.radio} />
                  }
                  label={
                    isSmallScreen
                      ? "Full Amount"
                      : "I would like to redeem my entire balance"
                  }
                  disabled={isSubmitting}
                />
                <FormControlLabel
                  value="partial"
                  control={
                    <Radio disabled={isSubmitting} className={classes.radio} />
                  }
                  label={
                    isSmallScreen
                      ? "Partial Amount"
                      : "I would like to redeem a partial amount"
                  }
                  disabled={isSubmitting}
                />
              </Field>
            </FormControl>
            {values.type === "partial" && (
              <Field
                component={FormikTextField}
                name="amount"
                label="Amount to Redeem"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(event) =>
                  setFieldValue("amount", event.target.value.replace(/\D/g, ""))
                }
                value={`$${formatWithCommas(values.amount)}`}
                helperText={
                  errors.amount ||
                  balances.find((fund) => fund.fundId === values.fund)
                    ? `Your current balance is ${dollarize(
                        balances.find((fund) => fund.fundId === values.fund)
                          .balance
                      )}`
                    : ""
                }
              />
            )}
            <Typography variant="body1" className={classes.notice}>
              Please note that redemptions are processed at the end of the month
              and require a minimum 2-weeks notice.
            </Typography>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              className={clsx(classes.submitButton, "button-gillsans")}
            >
              Initiate Redemption
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

NewRedemption.propTypes = propTypes;
NewRedemption.defaultProps = defaultProps;
