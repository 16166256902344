export const SIGN_IN = "/sign-in";
export const RESET_PASSWORD = "/reset-password";
export const LOG_OUT = "/log-out";
export const DASHBOARD = "/";
export const TRANSACTIONS = "/transactions";
export const SETTINGS = "/settings";
export const SETTINGS_SECURITY = "/settings/security";
export const DOCUMENTS = "/documents";
export const DOCUMENTS_STATEMENTS = "/documents/statements";
export const DOCUMENTS_TAX = "/documents/tax";
export const DOCUMENTS_INVESTOR_UPDATES = "/documents/investor-updates";
export const DOCUMENTS_ONBOARDING = "/documents/lp-documents";
export const REDIRECT_URI = "/implicit/callback";
export const DOCUMENT_TYPE_STATEMENT = "STATEMENT";
export const DOCUMENT_TYPE_TAX = "TAX";
export const DOCUMENT_TYPE_ONBOARDING = "ONBOARDING";
export const DOCUMENT_TYPE_GENERAL = "GENERAL";


export const ROUTE_MAP = {
  [SIGN_IN]: "Sign In",
  [RESET_PASSWORD]: "Reset Password",
  [LOG_OUT]: "Log Out",
  [DASHBOARD]: "Dashboard",
  [TRANSACTIONS]: "Transactions",
  [SETTINGS]: "Settings",
  [SETTINGS_SECURITY]: "Settings",
  [DOCUMENTS]: "Documents",
  [DOCUMENTS_STATEMENTS]: "Documents",
  [DOCUMENTS_TAX]: "Documents",
  [DOCUMENTS_INVESTOR_UPDATES]: "Documents",
  [DOCUMENTS_ONBOARDING]: "Documents",
};
