import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {
    DOCUMENT_TYPE_STATEMENT,
    DOCUMENTS,
    DOCUMENTS_INVESTOR_UPDATES,
    DOCUMENTS_ONBOARDING,
    DOCUMENTS_STATEMENTS,
    DOCUMENTS_TAX,
} from "../constants/routes";
import {
    makeStyles,
    Paper,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import DocumentsList from "./DocumentsList";

const useStyles = makeStyles((theme) => ({
    main: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            marginTop: "49px",
            padding: "36px 0",
        },
    },
    paper: {
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        minHeight: "500px",
        position: "relative",
    },
    tab: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontFamily: "Gill Sans Std",
        fontWeight: 400,
        letterSpacing: "0.5px",
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.grey.main}`,
    },
}));

const propTypes = {};

const defaultProps = {};

export default function Documents(props) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const [documentsListParams, setDocumentsListParams] = useState({
        documentType: DOCUMENT_TYPE_STATEMENT
    });

    const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

    const getDocumentType = (location) => {
        if (location === DOCUMENTS_INVESTOR_UPDATES) {
            return "GENERAL";
        }
        if (location === DOCUMENTS_TAX) {
            return "TAX";
        }
        if (location === DOCUMENTS_ONBOARDING) {
            return "ONBOARDING";
        }
        return "STATEMENT";
    };

    useEffect(() => {
        if (location.pathname === DOCUMENTS) {
            setDocumentsListParams({
                    documentType: DOCUMENT_TYPE_STATEMENT
                }
            );
            history.push(DOCUMENTS_STATEMENTS);
        }
    }, [location, history]);

    return (
        <Paper className={classes.paper}>
            {isMediumScreen && (
                <Tabs
                    value={location.pathname}
                    className={classes.tabs}
                    onChange={(e, newValue) => {
                        const newDocumentType = getDocumentType(newValue);
                        //console.log("tab click, new document type: ", newDocumentType);
                        setDocumentsListParams({
                            documentType: newDocumentType
                        });
                        history.push(newValue);
                    }}
                    indicatorColor="primary"
                >
                    <Tab
                        value={DOCUMENTS_STATEMENTS}
                        label="Account Statements"
                        className={classes.tab}
                    />
                    <Tab
                        value={DOCUMENTS_TAX}
                        label="Tax Documents"
                        className={classes.tab}
                    />
                    <Tab
                        value={DOCUMENTS_ONBOARDING}
                        label="LP Documents"
                        className={classes.tab}
                    />
                    <Tab
                        value={DOCUMENTS_INVESTOR_UPDATES}
                        label="Investor Updates"
                        className={classes.tab}
                    />
                </Tabs>
            )}
            <DocumentsList parameters={documentsListParams}/>
        </Paper>
    );
}

Documents.propTypes = propTypes;
Documents.defaultProps = defaultProps;
