import { CALCULATE_LOT_PERFORMANCE_SUM_FUNDS } from "../constants/actions";

const initialState = [];

const lotPerformanceAllFundsReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case CALCULATE_LOT_PERFORMANCE_SUM_FUNDS:
      const { allTxs, sumCurrentBalances, historicalSumBalances } = action;
      const historicalSumBalancesArr = Object.entries(
        historicalSumBalances
      ).map(([timestamp, balance]) => ({
        timestamp,
        balance,
      }));

      const allCompletedTxs = allTxs.filter((tx) => tx.state === "COMPLETE");
      const hash = Object.create(null);

      // If two flows on the same day, need to sum them into the same day
      for (let { amount, lastUpdatedOn, type } of allCompletedTxs) {
        lastUpdatedOn = lastUpdatedOn.slice(0, 10);
        amount = type === "SUBSCRIPTION" ? amount : -1 * amount;
        if (hash[lastUpdatedOn]) hash[lastUpdatedOn] += amount;
        else hash[lastUpdatedOn] = amount;
      }

      const totalCompletedTxsNetttedByDay = Object.entries(hash).map(
        ([lastUpdatedOn, amount]) => ({
          lastUpdatedOn: lastUpdatedOn + "T05:00:00.000Z",
          amount,
        })
      );

      const allTxsWithBalanceData = totalCompletedTxsNetttedByDay.map((tx) => ({
        ...tx,
        balanceAtSubDate: historicalSumBalancesArr.find(
          (bal) => bal.timestamp < tx.lastUpdatedOn
        )
          ? historicalSumBalancesArr.find(
              (bal) => bal.timestamp < tx.lastUpdatedOn
            ).balance
          : 0,
      }));

      const allTxsWithEndVals = allTxsWithBalanceData.map((tx, i) => ({
        ...tx,
        balanceAtNextSub: allTxsWithBalanceData[i - 1]
          ? allTxsWithBalanceData[i - 1].balanceAtSubDate
          : sumCurrentBalances,
      }));

      // No need to discern Sub vs Red since it is done above with hash
      const allTxsWithHP = allTxsWithEndVals.map((tx) => ({
        ...tx,
        hp:
          (tx.balanceAtNextSub - (tx.balanceAtSubDate + tx.amount)) /
          (tx.balanceAtSubDate + tx.amount),
      }));

      const allTxsWithTWR = allTxsWithHP
        .slice()
        .sort((a, b) => (a.lastUpdatedOn > b.lastUpdatedOn ? 1 : -1))
        .map((tx, i) => ({
          ...tx,
          twr: allTxsWithHP
            .slice()
            .sort((a, b) => (a.lastUpdatedOn > b.lastUpdatedOn ? 1 : -1))
            .slice(i)
            .reduce((accum, curr) => accum * (1 + curr.hp), 1),
        }));

      return allTxsWithTWR;
    default:
      return state;
  }
};

export default lotPerformanceAllFundsReducer;
