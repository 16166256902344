import React from "react";
import { makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import SettingsPrimaryInfo from "./SettingsPrimaryInfo";
import SettingsPersonalDetails from "./SettingsPersonalDetails";
import ChangePassword from "./ChangePassword";
import { useHistory, useLocation } from "react-router-dom";
import { SETTINGS, SETTINGS_SECURITY } from "../constants/routes";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  tab: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontFamily: "Gill Sans Std",
    fontWeight: 400,
    letterSpacing: "0.5px",
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
}));

const useChildStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  form: {
    padding: theme.spacing(4),
  },
  header: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  logout: {
    fontFamily: "Gill Sans Std",
    fontWeight: 400,
  },
  notAllowed: {
    "& input": {
      cursor: "not-allowed",
    },
  },
  success: {
    color: theme.palette.success.main,
  },
}));

const propTypes = {};

const defaultProps = {};

export default function Settings(props) {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const childClasses = useChildStyles();
  return (
    <Paper className={classes.paper}>
      <Tabs
        value={location.pathname}
        onChange={(e, newValue) => history.push(newValue)}
        indicatorColor="primary"
        className={classes.tabs}
      >
        <Tab value={SETTINGS} label="Profile" className={classes.tab} />
        <Tab
          value={SETTINGS_SECURITY}
          label="Security"
          className={classes.tab}
        />
      </Tabs>
      {location.pathname === SETTINGS_SECURITY ? (
        <React.Fragment>
          <ChangePassword classes={childClasses} />
          {/* <TwoFactorAuthentication /> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <SettingsPrimaryInfo classes={childClasses} />
          <SettingsPersonalDetails classes={childClasses} />
        </React.Fragment>
      )}
    </Paper>
  );
}

Settings.propTypes = propTypes;
Settings.defaultProps = defaultProps;
