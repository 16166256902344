import { REDIRECT_URI } from "../constants/routes";
import { isDev, isDevDev } from "../util/helpers";

export const prodOktaConfig = {
  issuer: "https://auth.castlefunds.com/oauth2/ausjmmx2b2tDuCK8F0h7",
  redirectUri: window.location.origin + REDIRECT_URI,
  clientId: "0oaiw5dzmu3DXLuSJ0h7",
  scopes: ["openid", "profile", "email"],
};

export const devOktaConfig = {
  issuer: "https://auth.dev.castlefunds.com/oauth2/aush98ysncDMuuMjK356",
  redirectUri: window.location.origin + REDIRECT_URI,
  clientId: "0oagimnwey7O5ZN7j356",
  scopes: ["openid", "profile", "email"],
};

export const stageOktaConfig = {
  issuer: "https://auth.stage.castlefunds.com/oauth2/aush98ysncDMuuMjK356",
  redirectUri: window.location.origin + REDIRECT_URI,
  clientId: "0oagimnwey7O5ZN7j356",
  scopes: ["openid", "profile", "email"],
};

//export const oktaConfig = isDevDev() ? devOktaConfig : isDev() ? stageOktaConfig : prodOktaConfig;
export const oktaConfig = isDev() ? stageOktaConfig : prodOktaConfig;
