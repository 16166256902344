import React from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  IconButton,
  Divider,
  List,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import { DRAWER_WIDTH } from "../constants/properties";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Link, useLocation } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/ShowChart";
import TransactionIcon from "@material-ui/icons/SwapHoriz";
import DocumentIcon from "@material-ui/icons/Description";
import SettingsIcon from "@material-ui/icons/Settings";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import {
  DASHBOARD,
  TRANSACTIONS,
  SETTINGS,
  DOCUMENTS,
} from "../constants/routes";

const useStyles = makeStyles((theme) => ({
  contact: {
    marginTop: "auto",
    paddingBottom: 10,
    color: theme.palette.white.main,
    fontFamily: "Gill Sans Std",
  },
  contactHeader: {
    fontFamily: "Gill Sans Std",
    marginLeft: theme.spacing(2),
    letterSpacing: "0.8px",
  },
  contactText: {
    "& span": {
      fontWeight: 400,
      color: theme.palette.grey.light,
      fontFamily: "Gill Sans Std",
      fontSize: "13px",
    },
  },
  divider: {
    backgroundColor: "rgba(0,0,0,0.5)",
    "&.contact": {
      marginBottom: theme.spacing(1),
    },
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: DRAWER_WIDTH,
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  icon: {
    color: theme.palette.grey.dark,
    "&.active": {
      color: theme.palette.white.main,
    },
  },
  iconButton: {
    padding: "7px",
    "&:hover": {
      "& .hover-active": {
        color: theme.palette.white.main,
      },
    },
  },
  item: {
    paddingLeft: "21px",
    color: theme.palette.sideBarSelected.main,
    "&.active": {
      backgroundColor: theme.palette.sideBarSelected.main,
    },
  },
  itemText: {
    "& span": {
      fontWeight: 600,
      color: theme.palette.grey.dark,
      fontFamily: "Iowan Old Style",
    },
    "&.active": {
      "& span": {
        color: theme.palette.white.main,
      },
    },
  },
  logo: {
    display: "block",
    height: 50,
    width: "auto",
  },
  logoParent: {
    marginTop: "auto",
    marginBottom: 10,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}));

const propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function Sidebar({ open, setOpen }) {
  const classes = useStyles();
  const location = useLocation();
  const items = [
    {
      title: "Dashboard",
      url: DASHBOARD,
      icon: <DashboardIcon />,
    },
    {
      title: "Transactions",
      url: TRANSACTIONS,
      icon: <TransactionIcon />,
    },
    {
      title: "Documents",
      url: DOCUMENTS,
      icon: <DocumentIcon />,
    },
    {
      title: "Settings",
      url: SETTINGS,
      icon: <SettingsIcon />,
    },
  ];
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <img src="/brand/secondary-white.png" alt="" className={classes.logo} />
        <IconButton
          className={classes.iconButton}
          onClick={() => setOpen(!open)}
        >
          <ChevronLeftIcon className={clsx(classes.icon, "hover-active")} />
        </IconButton>
      </div>
      <Divider className={classes.divider} />
      <List>
        {items.map((item, i) => {
          const isActive = `/${location.pathname.split("/")[1]}` === item.url;
          return (
            <ListItem
              button
              component={Link}
              to={item.url}
              className={isActive ? clsx(classes.item, "active") : classes.item}
              key={i}
            >
              <ListItemIcon>
                {React.cloneElement(item.icon, {
                  className: isActive
                    ? clsx(classes.icon, "active")
                    : classes.icon,
                })}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                className={
                  isActive ? clsx(classes.itemText, "active") : classes.itemText
                }
              />
            </ListItem>
          );
        })}
      </List>
      <List className={classes.contact}>
        <Divider className={clsx(classes.divider, "contact")} />
        {open && (
          <Typography className={classes.contactHeader} variant="subtitle1">
            Contact Us
          </Typography>
        )}
        <ListItem
          button
          component="a"
          href="mailto:investors@castlefunds.com"
          target="_blank"
          className={classes.item}
        >
          <ListItemIcon className={clsx(classes.icon, "active")}>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="subtitle1">Questions?</Typography>
                  <Typography variant="body1">
                    Email us at: investors@castlefunds.com
                  </Typography>
                </React.Fragment>
              }
              placement="right"
            >
              <EmailIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText className={classes.contactText}>
            investors@castlefunds.com
          </ListItemText>
        </ListItem>
        <ListItem
          button
          component="a"
          href="tel:1-415-506-9067"
          className={classes.item}
        >
          <ListItemIcon className={clsx(classes.icon, "active")}>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="subtitle1">Questions?</Typography>
                  <Typography variant="body1">
                    Call us at: 415-506-9067
                  </Typography>
                </React.Fragment>
              }
              placement="right"
            >
              <PhoneIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText className={classes.contactText}>
            415-506-9067
          </ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;
