import {getFromCastleAPI, getFromCastleAPIWithUrl} from "../util/api-util";
import { RECEIVE_DOCUMENTS } from "../constants/actions";
import {getErrorMessage} from "../util/helpers";

export const getDocuments = (userId, count, fundId, documentType, textSearch, accessToken) => (dispatch) => {
    let parameters = "?count=" + count + ((fundId === null || fundId === undefined || fundId.trim() === "") ? "" : "&fundId=" + fundId);
    parameters = parameters + ((documentType === null || documentType === undefined || documentType.trim() === "") ? "" : "&documentType=" + documentType);
    parameters = parameters + ((textSearch === null || textSearch === undefined || textSearch.trim() === "") ? "" : "&description=" + textSearch);

    const url = `documents/users/${userId}/paginated` + parameters;
    // console.log("[getDocuments] url: ", url);
    return getFromCastleAPI(url, accessToken)
        .then((response) => {
            // console.log("[getDocuments]: (documentType/response) = ", documentType, response);
            return dispatch(
                receiveDocuments(response.data, documentType)
            );
        })
        .catch((error) => {
            console.log("[getDocuments] error: ", getErrorMessage(error));
            throw error;
        })
};

export const getDocumentsWithOffset = (userId, offset, documentType, accessToken) => (dispatch) => {
    // console.log("[getDocumentsByClientIdWithOffset] url: ", offset);
    return getFromCastleAPIWithUrl(offset, accessToken)
        .then((response) => {
            // console.log("[getDocumentsWithOffset]: (documentType/response) = ", documentType, response);
            return dispatch(
                receiveDocuments(response.data, documentType)
            );
        })
        .catch((error) => {
            console.log("[getDocumentsWithOffset] error: ", getErrorMessage(error));
            throw error;
        })
}

export const receiveDocuments = (data, documentType) => ({
    type: RECEIVE_DOCUMENTS,
    data,
});

