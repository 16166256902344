import React from "react";
import { Typography, Link } from "@material-ui/core";

export default function Copyright() {
  return (
    <Typography
      variant="body1"
      color="textSecondary"
      style={{ fontSize: "0.8rem", textAlign: "center" }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://castlefunds.com/">
        Castle Funds
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
