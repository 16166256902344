import React from "react";
import {
  makeStyles,
  Button,
  Typography,
  Modal,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider,
  Backdrop,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  dollarize,
  timestringize,
  capitalize,
  datestringize,
  datestringizeWithTime,
} from "../util/helpers";
import MaterialTable from "material-table";
import { useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ReactGA from "react-ga";
import NewRedemption from "./NewRedemption";
import NewSubscription from "./NewSubscription";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1,
  },
  cancelled: {
    color: "initial",
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardDivider: {
    width: "50%",
    margin: "0 auto",
  },
  cardTitle: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  close: {
    position: "absolute",
    right: "2%",
    top: "2%",
    cursor: "pointer",
  },
  complete: {
    color: theme.palette.deltaGreen.main,
  },
  form: {
    marginTop: theme.spacing(2),
  },
  lastUpdatedOn: {
    display: "table",
    textAlign: "right",
    marginRight: "auto",
    marginLeft: 0,
    "& p": {
      margin: 0,
    },
  },
  modal: {
    backgroundColor: theme.palette.grey.light,
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    padding: theme.spacing(3),
    borderRadius: "7px",
    boxShadow: "0 2px 6px 0 rgba(8, 19, 28, .2)",
    "&.redemption": {
      [theme.breakpoints.down("md")]: {
        width: "70%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "85%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      top: "44%",
      textAlign: "center",
    },
  },
  modalSummary: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  newActionButton: {
    margin: theme.spacing(1),
  },
  notice: {
    maxWidth: "440px",
    margin: "0 auto",
    marginTop: theme.spacing(4),
    textAlign: "center",
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
      fontSize: "12px",
    },
  },
  property: {
    fontWeight: 300,
  },
  radio: {
    color: `${theme.palette.primary.main} !important`,
  },
  radioGroup: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  speedDial: {
    position: "fixed",
    bottom: "70px !important",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px",
    paddingLeft: "24px",
  },
  working: {
    color: theme.palette.babyBlue.main,
  },
}));

function SmallScreenTransactions({ setModal }) {
  const classes = useStyles();
  const txs = useSelector((state) => state.txs);
  const funds = useSelector((state) => state.funds);

  const [open, setOpen] = useState(false);

  const actions = [
    {
      icon: <RemoveIcon />,
      name: "Redeem",
      component: <NewRedemption setModal={setModal} />,
    },
    {
      icon: <AddIcon />,
      name: "Subscribe",
      component: <NewSubscription setModal={setModal} />,
    },
  ];

  return (
    <div>
      {txs.map((tx) => (
        <Card key={tx.id} className={classes.card}>
          <CardContent>
            <Typography
              variant="h5"
              className={classes.cardTitle}
            >{`${datestringize(tx.createdOn)} ${
              tx.type === "WITHDRAWAL" ? "Redemption" : capitalize(tx.type)
            }`}</Typography>
            <Divider className={classes.cardDivider} />
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="body1" className={classes.property}>
                        Amount
                      </Typography>
                      <Typography variant="body1">
                        {tx.type === "WITHDRAWAL" && tx.state !== "COMPLETE"
                          ? `${tx.amount.toLocaleString("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}%`
                          : dollarize(tx.amount)}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="body1" className={classes.property}>
                        Type
                      </Typography>
                      <Typography variant="body1">
                        {tx.type === "WITHDRAWAL"
                          ? "Redemption"
                          : capitalize(tx.type)}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="body1" className={classes.property}>
                        Fund
                      </Typography>
                      <Typography variant="body1">
                        {funds[tx.indexId] ? funds[tx.indexId].name : ""}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="body1" className={classes.property}>
                        Created On
                      </Typography>
                      <Typography variant="body1">
                        {datestringizeWithTime(tx.createdOn)}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="body1" className={classes.property}>
                        Last Updated
                      </Typography>
                      <Typography variant="body1">
                        {datestringizeWithTime(tx.lastUpdatedOn)}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="body1" className={classes.property}>
                        Status
                      </Typography>
                      <Typography
                        variant="body1"
                        className={
                          tx.state === "COMPLETE"
                            ? classes.complete
                            : tx.state === "PENDING" ||
                              tx.state === "STAGED" ||
                              tx.state === "IN_PROGRESS"
                            ? classes.working
                            : classes.cancelled
                        }
                      >
                        {capitalize(tx.state)}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      ))}
      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={() => setOpen(false)}
      />
      <SpeedDial
        ariaLabel="New Transaction"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        direction="up"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => {
              setModal({ isOpen: true, component: action.component });
              ReactGA.event({
                category: "Transactions",
                action: "Opened Modal",
                label: action.name,
              });
              setOpen(false);
            }}
            tooltipOpen={true}
          />
        ))}
      </SpeedDial>
    </div>
  );
}

function MediumScreenAndLargerTransactions({ setModal }) {
  const classes = useStyles();
  const txs = useSelector((state) => state.txs);
  const funds = useSelector((state) => state.funds);
  const balances = useSelector((state) => state.balances);

  const data = txs.length > 0 ? txs : [{}];

  return (
    <MaterialTable
      title="Transaction History"
      options={{
        search: false,
        rowStyle: {
          letterSpacing: "0.3px",
        },
      }}
      columns={[
        {
          title: "Date Created",
          field: "createdOn",
          headerStyle: {
            width: 60,
            maxWidth: 60,
            fontWeight: 300,
          },
          cellStyle: {
            width: 60,
            maxWidth: 60,
          },
          render: (rowData) =>
            rowData.createdOn ? (
              <p>{rowData.createdOn}</p>
            ) : (
              <Skeleton animation="wave" height={20} width="80%" />
            ),
        },
        {
          title: "Fund",
          field: "fund",
          headerStyle: {
            width: 200,
            minWidth: 200,
            fontWeight: 300,
          },
          cellStyle: {
            width: 200,
            minWidth: 200,
          },
          render: (rowData) =>
            rowData.fund ? (
              <p>{rowData.fund}</p>
            ) : (
              <Skeleton animation="wave" height={20} width="80%" />
            ),
        },
        {
          title: "Type",
          field: "type",
          headerStyle: {
            fontWeight: 300,
          },
          render: (rowData) =>
            rowData.type ? (
              <p>
                {rowData.type === "WITHDRAWAL"
                  ? "Redemption"
                  : capitalize(rowData.type)}
              </p>
            ) : (
              <Skeleton animation="wave" height={20} width="80%" />
            ),
        },
        {
          title: "Amount",
          field: "amount",
          headerStyle: {
            fontWeight: 300,
          },
          render: (rowData) =>
            rowData.amount ? (
              <p>
                {dollarize(rowData.amount)}
              </p>
            ) : (
              <Skeleton animation="wave" height={20} width="80%" />
            ),
        },
        {
          title: "Last Updated",
          field: "lastUpdatedOn",
          headerStyle: {
            fontWeight: 300,
          },
          render: (rowData) =>
            rowData.lastUpdatedOn ? (
              <div className={classes.lastUpdatedOn}>
                <p>{datestringize(rowData.lastUpdatedOn)}</p>
                <p>{timestringize(rowData.lastUpdatedOn)}</p>
              </div>
            ) : (
              <Skeleton animation="wave" height={20} width="80%" />
            ),
        },
        {
          title: "Status",
          field: "status",
          headerStyle: {
            fontWeight: 300,
          },
          render: (rowData) =>
            rowData.status ? (
              <p
                className={
                  rowData.status === "COMPLETE"
                    ? classes.complete
                    : rowData.status === "PENDING" ||
                      rowData.status === "STAGED" ||
                      rowData.status === "IN_PROGRESS"
                    ? classes.working
                    : classes.cancelled
                }
              >
                {capitalize(rowData.status)}
              </p>
            ) : (
              <Skeleton animation="wave" height={20} width="80%" />
            ),
        },
      ]}
      data={data.map((tx) => ({
        createdOn: datestringize(tx.createdOn),
        fund: funds[tx.indexId] ? funds[tx.indexId].name : "",
        type: tx.type,
        amount: tx.amount,
        lastUpdatedOn: tx.lastUpdatedOn,
        status: tx.state,
      }))}
      components={{
        Toolbar: (props) => (
          <div className={classes.toolbar}>
            <Typography variant="h5">{props.title}</Typography>
            <div style={{ padding: "0px 10px" }}>
              <Button
                onClick={() => {
                  setModal({
                    isOpen: true,
                    component: <NewSubscription setModal={setModal} />,
                  });
                  ReactGA.event({
                    category: "Transactions",
                    action: "Opened Modal",
                    label: "Subscribe",
                  });
                }}
                variant="outlined"
                className={clsx(classes.newActionButton, "button-gillsans")}
              >
                New Subscription
              </Button>
              <Button
                onClick={() => {
                  setModal({
                    isOpen: true,
                    component: <NewRedemption setModal={setModal} />,
                  });
                  ReactGA.event({
                    category: "Transactions",
                    action: "Opened Modal",
                    label: "Redeem",
                  });
                }}
                variant="outlined"
                className={clsx(classes.newActionButton, "button-gillsans")}
                disabled={balances.length === 0}
              >
                New Redemption
              </Button>
            </div>
          </div>
        ),
      }}
    />
  );
}

const propTypes = {};

const defaultProps = {};

export default function Transactions() {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [modal, setModal] = useState({
    isOpen: false,
    component: null,
  });

  return (
    <React.Fragment>
      {isMediumScreen ? (
        <MediumScreenAndLargerTransactions setModal={setModal} />
      ) : (
        <SmallScreenTransactions setModal={setModal} />
      )}
      <Modal
        open={modal.isOpen}
        onClose={() =>
          setModal({
            isOpen: false,
            component: null,
          })
        }
      >
        {modal.component || <div />}
      </Modal>
    </React.Fragment>
  );
}

Transactions.propTypes = propTypes;
Transactions.defaultProps = defaultProps;
