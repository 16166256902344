import React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/ShowChart";
import TransactionIcon from "@material-ui/icons/SwapHoriz";
import DocumentIcon from "@material-ui/icons/Description";
import { useHistory, useLocation } from "react-router-dom";
import { DASHBOARD, TRANSACTIONS, DOCUMENTS } from "../constants/routes";

const useStyles = makeStyles((theme) => ({
  action: {
    "& .MuiBottomNavigationAction-label": {
      fontFamily: "Gill Sans Std",
      marginTop: "5px",
      "&.Mui-selected": {
        fontWeight: 300,
      },
    },
  },
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid",
    borderColor: theme.palette.grey.main,
    zIndex: 1000,
  },
}));

const propTypes = {};

const defaultProps = {};

export default function BottomNav() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  return (
    <BottomNavigation
      value={`/${location.pathname.split("/")[1]}`}
      onChange={(event, newValue) => {
        history.push(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        value={DASHBOARD}
        label="Dashboard"
        className={classes.action}
        icon={<DashboardIcon />}
      />
      <BottomNavigationAction
        value={TRANSACTIONS}
        label="Transactions"
        className={classes.action}
        icon={<TransactionIcon />}
      />
      <BottomNavigationAction
        value={DOCUMENTS}
        label="Documents"
        className={classes.action}
        icon={<DocumentIcon />}
      />
    </BottomNavigation>
  );
}

BottomNav.propTypes = propTypes;
BottomNav.defaultProps = defaultProps;
