import React from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { TextField as FormikTextField } from "formik-material-ui";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { LOG_OUT } from "../constants/routes";
import { useHistory } from "react-router-dom";
import { capitalize } from "../util/helpers";
import { useOktaAuth } from "@okta/okta-react";
import { updateUserProfile } from "../actions/profile";
import { Alert } from "@material-ui/lab";

const propTypes = {
  classes: PropTypes.object.isRequired,
};

const defaultProps = {};

const initialStatus = {
  severity: null,
  message: null,
};

export default function SettingsPrimaryInfo({ classes }) {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();

  const isSmallScreenAndSmaller = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.user);
  const profile = useSelector((state) => state.profile);

  return (
    <Formik
      initialValues={{
        firstName: profile.firstName || "",
        lastName: profile.lastName || "",
        lpName: profile.lpName || "",
        email: profile.email || "",
        investorType: profile.investorType || "",
      }}
      enableReinitialize
      initialStatus={initialStatus}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .min(2, "Must be at least 2 characters long")
          .required("Required"),
        lastName: Yup.string()
          .min(2, "Must be at least 2 characters long")
          .required("Required"),
        lpName: Yup.string()
          .min(2, "Must be at least 2 characters long")
          .required("Required"),
        investorType: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
      })}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        console.log("submitting");
        dispatch(
          updateUserProfile(
            {
              profile: {
                firstName: values.firstName
                  .split(" ")
                  .map((word) => capitalize(word))
                  .join(" "),
                lastName: values.lastName
                  .split(" ")
                  .map((word) => capitalize(word))
                  .join(" "),
                email: values.email,
              },
            },
            user.id,
            authState.accessToken.value
          )
        )
          .then((response) => {
            setSubmitting(false);
            setStatus({
              message: "Information saved.",
              severity: "success",
            });
            setTimeout(() => setStatus(initialStatus), 5000);
          })
          .catch((error) => {
            setSubmitting(false);
            setStatus({
              message:
                "An error occurred. Please check your info and try again.",
              severity: "error",
            });
            setTimeout(() => setStatus(initialStatus), 5000);
          });
      }}
    >
      {({ isSubmitting, values, status }) => (
        <Form className={classes.form}>
          <Grid container>
            {isSmallScreenAndSmaller && (
              <Grid item xs={12} sm={12}>
                <Box paddingBottom="20px">
                  <Button
                    variant="contained"
                    onClick={() => history.push(LOG_OUT)}
                    color="primary"
                    className={classes.logout}
                  >
                    Log Out
                  </Button>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={5}>
              <Typography className={classes.header} variant="h5">
                Primary Info
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Field
                    fullWidth
                    name="firstName"
                    label="First Name"
                    component={FormikTextField}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    fullWidth
                    name="lastName"
                    label="Last Name"
                    component={FormikTextField}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    disabled
                    fullWidth
                    name="lpName"
                    label="Official LP Name"
                    component={FormikTextField}
                    variant="outlined"
                    className={classes.notAllowed}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    disabled
                    fullWidth
                    name="investorType"
                    label="Account Type"
                    component={FormikTextField}
                    value={
                      values.investorType[0] === "ira" ||
                      values.investorType[0] === "llc"
                        ? values.investorType[0].toUpperCase()
                        : capitalize(values.investorType[0])
                    }
                    variant="outlined"
                    className={classes.notAllowed}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    disabled
                    fullWidth
                    name="email"
                    label="Email Address"
                    component={FormikTextField}
                    variant="outlined"
                    className={classes.notAllowed}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    className="button-gillsans"
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                </Grid>
                <Grid item xs={12} md={9}>
                  {status.severity && (
                    <Alert severity={status.severity}>{status.message}</Alert>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

SettingsPrimaryInfo.propTypes = propTypes;
SettingsPrimaryInfo.defaultProps = defaultProps;
