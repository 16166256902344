import React from "react";
import { Typography, Container } from "@material-ui/core";
import { useEffect } from "react";
import { resetDefaults } from "../actions/user";
import { useDispatch } from "react-redux";
import { SIGN_IN } from "../constants/routes";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";

const propTypes = {};

export default function LogOut() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const fallbackTimeout = setTimeout(() => history.push(SIGN_IN), 5000);
    const secondsInterval = setInterval(
      () => setSeconds((seconds) => seconds + 1),
      1000
    );
    dispatch(resetDefaults());
    oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin + SIGN_IN,
    });
    return () => {
      clearTimeout(fallbackTimeout);
      clearInterval(secondsInterval);
    };
  }, [oktaAuth, dispatch, history]);

  const elipses = seconds % 3 === 0 ? "..." : seconds % 3 === 1 ? "." : "..";

  return (
    <Container
      component="main"
      style={{ position: "relative", height: "100vh" }}
    >
      <Typography
        variant="h4"
        style={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        Logging out{elipses}
      </Typography>
    </Container>
  );
}

LogOut.propTypes = propTypes;
