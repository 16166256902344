import { RECEIVE_USER, RESET_DEFAULTS } from "../constants/actions";

const initialState = {
  organizationId: null,
  firstName: null,
  lastName: null,
  id: null,
  state: null,
  email: null
};

const userReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_USER:
      return action.data;
    case RESET_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
