import { RECEIVE_USER_PROFILE, RESET_DEFAULTS } from "../constants/actions";

const initialState = {
  firstName: "",
  lastName: "",
  mobilePhone: "",
  secondEmail: "",
  title: "",
  login: "",
  email: "",
};

const profileReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_USER_PROFILE:
      return action.data;
    case RESET_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default profileReducer;
