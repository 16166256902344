import React from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { TextField as FormikTextField } from "formik-material-ui";
import { Button, Grid, Typography } from "@material-ui/core";
import { capitalize } from "../util/helpers";
import { useOktaAuth } from "@okta/okta-react";
import { updateUserProfile } from "../actions/profile";
import moment from "moment";
import { COUNTRIES } from "../constants/countries";
import { Alert } from "@material-ui/lab";

const propTypes = {
  classes: PropTypes.object.isRequired,
};

const defaultProps = {};

const initialStatus = {
  severity: null,
  message: null,
};

export default function SettingsPersonalDetails({ classes }) {
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const profile = useSelector((state) => state.profile);

  return (
    <Formik
      initialValues={{
        streetAddress: profile.streetAddress || "",
        city: profile.city || "",
        state: profile.state || "",
        zipCode: profile.zipCode || "",
        countryCode: profile.countryCode || "",
        dateOfBirth: profile.dateOfBirth || "",
      }}
      enableReinitialize
      initialStatus={initialStatus}
      validationSchema={Yup.object({
        streetAddress: Yup.string()
          .min(2, "Must be at least 2 characters long")
          .required("Required"),
        city: Yup.string()
          .min(2, "Must be at least 2 characters long")
          .required("Required"),
        state: Yup.string()
          .min(2, "Must be at least 2 characters long")
          .max(2, "Cannot exceed 2 characters")
          .required("Required"),
        zipCode: Yup.string()
          .min(2, "Must be at least 2 characters long")
          .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Must be a valid zip code")
          .required("Required"),
        countryCode: Yup.string()
          .min(2, "Must be at least 2 characters long")
          .required("Required"),
      })}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        console.log("submitting");
        dispatch(
          updateUserProfile(
            {
              profile: {
                streetAddress: values.streetAddress
                  .split(" ")
                  .map((word) => capitalize(word))
                  .join(" "),
                city: values.city
                  .split(" ")
                  .map((word) => capitalize(word))
                  .join(" "),
                state: values.state.toUpperCase(),
                zipCode: values.zipCode,
              },
            },
            user.id,
            authState.accessToken.value
          )
        )
          .then((response) => {
            setSubmitting(false);
            setStatus({
              message: "Information saved.",
              severity: "success",
            });
            setTimeout(() => setStatus(initialStatus), 5000);
          })
          .catch((error) => {
            setSubmitting(false);
            setStatus({
              message:
                "An error occurred. Please check your info and try again.",
              severity: "error",
            });
            setTimeout(() => setStatus(initialStatus), 5000);
          });
      }}
    >
      {({ isSubmitting, values, status }) => (
        <Form className={classes.form}>
          <Grid container>
            <Grid item xs={12} md={5}>
              <Typography className={classes.header} variant="h5">
                Personal Details
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    disabled
                    fullWidth
                    name="dateOfBirth"
                    label="Date of Birth"
                    value={moment(values.dateOfBirth)
                      .utc()
                      .format("MM/DD/YYYY")}
                    component={FormikTextField}
                    variant="outlined"
                    className={classes.notAllowed}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="streetAddress"
                    label="Address"
                    component={FormikTextField}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    fullWidth
                    name="city"
                    label="City"
                    component={FormikTextField}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    fullWidth
                    name="state"
                    label="State"
                    component={FormikTextField}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    fullWidth
                    name="zipCode"
                    label="Zip Code"
                    component={FormikTextField}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    fullWidth
                    disabled
                    name="countryCode"
                    label="Country"
                    component={FormikTextField}
                    value={COUNTRIES[values.countryCode] || ""}
                    variant="outlined"
                    className={classes.notAllowed}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    className="button-gillsans"
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                </Grid>
                <Grid item xs={12} md={9}>
                  {status.severity && (
                    <Alert severity={status.severity}>{status.message}</Alert>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

SettingsPersonalDetails.propTypes = propTypes;
SettingsPersonalDetails.defaultProps = defaultProps;
