import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, makeStyles } from "@material-ui/core";
import { dollarize } from "../util/helpers";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      textAlign: "center",
    },
  },
}));

const propTypes = {};

const defaultProps = {};

export default function BalancesHeader() {
  const classes = useStyles();
  const balances = useSelector((state) => state.balances);

  const [balance, setBalance] = useState(null);

  useEffect(() => {
    setBalance(balances.reduce((accum, curr) => accum + curr.balance, 0));
  }, [balances]);

  return (
    <div className={classes.container}>
      <Typography variant="h5">Portfolio Balance</Typography>
      {balance ? (
        <Typography variant="h3">{dollarize(balance)}</Typography>
      ) : (
        <Skeleton
          animation="wave"
          height={90}
          width={300}
          style={{ marginTop: "-15px" }}
        />
      )}
    </div>
  );
}

BalancesHeader.propTypes = propTypes;
BalancesHeader.defaultProps = defaultProps;
