import {
  RECEIVE_USER_TX_HISTORY,
  RESET_DEFAULTS,
  SUBMIT_USER_TX,
} from "../constants/actions";

const initialState = [];

// {
// uid: String
// amount: Number
// indexId: String
// lastUpdatedOn: String ISO-8601
// id: String
// state: String
// type: String
// createdOn: String ISO-8601
// }

const txsReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_USER_TX_HISTORY:
      return action.data.sort((a, b) =>
        a.lastUpdatedOn > b.lastUpdatedOn ? -1 : 1
      );
    case SUBMIT_USER_TX:
      return [action.data].concat(state);
    case RESET_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default txsReducer;
