import { CALCULATE_LOT_PERFORMANCE_BY_FUND_ID } from "../constants/actions";

const initialState = {};

const lotPerformanceByFundIdReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case CALCULATE_LOT_PERFORMANCE_BY_FUND_ID:
      const { fundBalance, fundId, fundTxs, historicalFundBalances } = action;
      const fundTxsWithBalanceData = fundTxs.map((tx) => ({
        ...tx,
        balanceAtSubDate: historicalFundBalances.find(
          (bal) => bal.timestamp < tx.lastUpdatedOn
        )
          ? historicalFundBalances.find(
              (bal) => bal.timestamp < tx.lastUpdatedOn
            ).balance
          : 0,
      }));
      const fundTxsWithEndVals = fundTxsWithBalanceData.map((tx, i) => ({
        ...tx,
        balanceAtNextSub: fundTxsWithBalanceData[i - 1]
          ? fundTxsWithBalanceData[i - 1].balanceAtSubDate
          : fundBalance,
      }));
      const fundTxsWithHP = fundTxsWithEndVals.map((tx) => ({
        ...tx,
        hp:
          tx.type === "SUBSCRIPTION"
            ? (tx.balanceAtNextSub - (tx.balanceAtSubDate + tx.amount)) /
              (tx.balanceAtSubDate + tx.amount)
            : (tx.balanceAtNextSub - (tx.balanceAtSubDate - tx.amount)) /
              (tx.balanceAtSubDate - tx.amount),
      }));

      const fundTxsWithTWR = fundTxsWithHP
        .slice()
        .sort((a, b) => (a.lastUpdatedOn > b.lastUpdatedOn ? 1 : -1))
        .map((tx, i) => ({
          ...tx,
          twr: fundTxsWithHP
            .slice()
            .sort((a, b) => (a.lastUpdatedOn > b.lastUpdatedOn ? 1 : -1))
            .slice(i)
            .reduce((accum, curr) => accum * (1 + curr.hp), 1),
        }))
        .sort((a, b) => (a.lastUpdatedOn > b.lastUpdatedOn ? -1 : 1));
      return {
        ...state,
        [fundId]: fundTxsWithTWR,
      };
    default:
      return state;
  }
};

export default lotPerformanceByFundIdReducer;
