export const RECEIVE_USER = "RECEIVE_USER";
export const RECEIVE_BALANCES = "RECEIVE_BALANCES";
export const RECEIVE_HISTORICAL_BALANCES = "RECEIVE_HISTORICAL_BALANCES";
export const RECEIVE_FUND_INFO = "RECEIVE_FUND_INFO";
export const RECEIVE_USER_TX_HISTORY = "RECEIVE_USER_TX_HISTORY";
export const RECEIVE_DOCUMENTS = "RECEIVE_DOCUMENTS";
export const RECEIVE_DOCUMENTS_STATEMENT = "RECEIVE_DOCUMENTS_STATEMENT";
export const RECEIVE_DOCUMENTS_TAX = "RECEIVE_DOCUMENTS_TAX";
export const RECEIVE_DOCUMENTS_ONBOARDING = "RECEIVE_DOCUMENTS_ONBOARDING";
export const RECEIVE_DOCUMENTS_GENERAL = "RECEIVE_DOCUMENTS_GENERAL";
export const RECEIVE_USER_PROFILE = "RECEIVE_USER_PROFILE";
export const SUBMIT_USER_TX = "SUBMIT_USER_TX";
export const SUM_HISTORICAL_BALANCES = "SUM_HISTORICAL_BALANCES";
export const CALCULATE_LOT_PERFORMANCE_BY_FUND_ID =
  "CALCULATE_LOT_PERFORMANCE_BY_FUND_ID";
export const CALCULATE_LOT_PERFORMANCE_SUM_FUNDS =
  "CALCULATE_LOT_PERFORMANCE_SUM_FUNDS";

export const SET_SHOW_FUND_HOLDINGS_DISCLAIMER =
  "SET_SHOW_FUND_HOLDINGS_DISCLAIMER";
export const RESET_DEFAULTS = "RESET_DEFAULTS";
