import { combineReducers } from "redux";
import balancesReducer from "./balances";
import userReducer from "./user";
import historicalBalancesReducer from "./historicalBalances";
import fundsReducer from "./funds";
import txsReducer from "./txs";
import documentsReducer from "./documents";
import profileReducer from "./profile";
import historicalSumBalancesReducer from "./historicalSumBalances";
import fundHoldingsDisclaimerReducer from "./fundHoldingsDisclaimer";
import lotPerformanceReducerByFundId from "./lotPerformanceByFundId";
import lotPerformanceAllFundsReducer from "./lotPerformanceAllFunds";

const rootReducer = combineReducers({
  balances: balancesReducer,
  documents: documentsReducer,
  fundHoldingsDisclaimer: fundHoldingsDisclaimerReducer,
  funds: fundsReducer,
  historicalBalances: historicalBalancesReducer,
  historicalSumBalances: historicalSumBalancesReducer,
  lotPerformanceAllFunds: lotPerformanceAllFundsReducer,
  lotPerformanceByFundId: lotPerformanceReducerByFundId,
  profile: profileReducer,
  txs: txsReducer,
  user: userReducer,
});

export default rootReducer;
