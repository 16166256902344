import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
// import { enableBatching } from 'redux-batched-actions';

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;

window.getState = store.getState;
