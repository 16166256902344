import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import {
  netCompletedTransactionsAfterISOString,
  percentize,
} from "../util/helpers";
import moment from "moment";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";
import { calculateLotPerformanceSumFunds } from "../actions/performance";

const useStyles = makeStyles((theme) => ({
  activeTimerange: {
    marginLeft: theme.spacing(0.75),
  },
  negative: {
    color: theme.palette.deltaRed.main,
  },
  performanceList: {
    display: "flex",
  },
  performanceItem: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      height: "30px",
      width: "1px",
      backgroundColor: theme.palette.grey.main,
      left: 0,
      top: "7px",
    },
    "&:first-of-type": {
      "&::after": {
        content: "",
        height: 0,
        width: 0,
      },
    },
  },
  pnl: {
    letterSpacing: "0.5px",
  },
  positive: {
    color: theme.palette.deltaGreen.main,
  },
  property: {
    fontWeight: 300,
  },
  mobilePerformance: {
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  zero: {
    color: "inherit",
  },
}));

function MediumScreenAndSmallerPerformance({ activeTimerange, performance }) {
  const classes = useStyles();
  const result = performance[activeTimerange];
  const pnl = percentize(result);

  return typeof result === "number" && !isNaN(result) ? (
    <Typography varaint="subtitle1" className={classes.mobilePerformance}>
      <span
        className={
          pnl === "0.00%" || pnl === "-0.00%"
            ? clsx(classes.pnl, classes.zero)
            : result > 0
            ? clsx(classes.pnl, classes.positive)
            : clsx(classes.pnl, classes.negative)
        }
      >
        {pnl === "-0.00%" ? "0.00%" : pnl}
      </span>
      <span className={classes.activeTimerange}>
        {activeTimerange === "ALL" ? "All-time" : activeTimerange}
      </span>
    </Typography>
  ) : (
    <Typography
      varaint="subtitle1"
      className={clsx(classes.pnl, classes.zero, classes.mobilePerformance)}
    >
      --
    </Typography>
  );
}

function LargeScreenAndLargerPerformance({ performance }) {
  const classes = useStyles();
  return (
    <div className={classes.performanceList}>
      {Object.entries(performance)
        .filter(
          ([timerange, result]) =>
            !!result && typeof result === "number" && !isNaN(result)
        )
        .map(([timerange, result], i) => {
          const pnl =
            percentize(result) === "-0.00%" ? "0.00%" : percentize(result);
          return (
            <div className={classes.performanceItem} key={i}>
              <Typography variant="caption">{timerange}</Typography>
              <Typography
                varaint="subtitle1"
                className={
                  pnl === "0.00%"
                    ? clsx(classes.pnl, classes.zero)
                    : result > 0
                    ? clsx(classes.pnl, classes.positive)
                    : clsx(classes.pnl, classes.negative)
                }
              >
                {pnl}
              </Typography>
            </div>
          );
        })}
    </div>
  );
}

const propTypes = {
  activeTimerange: PropTypes.string,
};

const defaultProps = {
  activeTimerange: "ALL",
};

export default function Performance({ activeTimerange }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const isMediumScreenOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const balances = useSelector((state) => state.balances);
  const historicalSumBalances = useSelector(
    (state) => state.historicalSumBalances
  );
  const lotPerformanceAllFunds = useSelector(
    (state) => state.lotPerformanceAllFunds
  );
  const txs = useSelector((state) => state.txs);

  const [sumCurrentBalances, setSumCurrentBalances] = useState(null);
  const [performance, setPerformance] = useState({
    "1d": null,
    "7d": null,
    "1m": null,
    "3m": null,
    YTD: null,
    "12m": null,
    ALL: null,
  });

  useEffect(() => {
    setSumCurrentBalances(
      balances.reduce((accum, curr) => accum + curr.balance, 0)
    );
  }, [balances]);

  useEffect(() => {
    // Calculate lot performance across all funds
    if (
      !!sumCurrentBalances &&
      Object.keys(historicalSumBalances).length > 0 &&
      txs.length > 0
    ) {
      dispatch(
        calculateLotPerformanceSumFunds(
          txs,
          sumCurrentBalances,
          historicalSumBalances
        )
      );
    }
  }, [sumCurrentBalances, historicalSumBalances, txs, dispatch]);

  useEffect(() => {
    // get past date that timerange is referring to
    // look at lot performance across all funds
    // if no transaction after the past date => (currBal - pastBal) / pastBal
    // if ALL (i.e. from start) => chain individual lot TWR's
    // if 1 or more transaction after timerange =>
    // (balAtNextTx - pastBal) / pastBal = TWR for first
    // chain remaining TWR's to present

    const historicalSumBalancesArr = Object.values(historicalSumBalances);

    if (
      historicalSumBalancesArr.length > 0 &&
      lotPerformanceAllFunds.length > 0 &&
      !!sumCurrentBalances
    ) {
      console.log(
        "[Performance] lotPerformanceAllFunds",
        lotPerformanceAllFunds
      );

      const dayAgoMoment = moment().subtract(1, "days");
      const sevenDaysAgoMoment = moment().subtract(7, "days");
      const monthAgoMoment = moment().subtract(1, "months");
      const threeMonthsAgoMoment = moment().subtract(3, "months");
      const twelveMonthsAgoMoment = moment().subtract(12, "months");
      const startOfYearMoment = moment().startOf("year");

      const monthAgoInDays = moment().diff(monthAgoMoment, "days") - 1;
      const threeMonthsAgoInDays =
        moment().diff(threeMonthsAgoMoment, "days") - 1;
      const twelveMonthsInDays =
        moment().diff(twelveMonthsAgoMoment, "days") - 1;
      const startOfYearInDays = moment().diff(startOfYearMoment, "days") + 1;

      const pastDatesByTimerange = {
        "1d": dayAgoMoment.toISOString(),
        "7d": sevenDaysAgoMoment.toISOString(),
        "1m": monthAgoMoment.toISOString(),
        "3m": threeMonthsAgoMoment.toISOString(),
        "12m": twelveMonthsAgoMoment.toISOString(),
        YTD: startOfYearMoment.toISOString(),
      };

      const pastBalancesByTimerange = {
        "1d": historicalSumBalancesArr[0],
        "7d": historicalSumBalancesArr[6],
        "1m": historicalSumBalancesArr[monthAgoInDays],
        "3m": historicalSumBalancesArr[threeMonthsAgoInDays],
        "12m": historicalSumBalancesArr[twelveMonthsInDays],
        YTD: historicalSumBalancesArr[startOfYearInDays],
        ALL: 0,
      };

      const calculateDollarWeightedReturnsSinceTimerange = (timerange) => {
        // TODO: https://www.kitces.com/blog/twr-dwr-irr-calculations-performance-reporting-software-methodology-gips-compliance/
        if (timerange === "ALL") {
          const netAllTxs = netCompletedTransactionsAfterISOString(txs);
          return netAllTxs <= 0
            ? lotPerformanceAllFunds[0].twr - 1
            : (sumCurrentBalances - netAllTxs) / netAllTxs;
        }
        if (!!pastBalancesByTimerange[timerange]) {
          const lotPerformancesAfterTimerange = lotPerformanceAllFunds.filter(
            (lot) => lot.lastUpdatedOn > pastDatesByTimerange[timerange]
          );
          if (lotPerformancesAfterTimerange.length > 0) {
            const firstLotPerformanceAfterTimerange =
              lotPerformancesAfterTimerange[0];

            const timerangeLot = {
              balanceAtNextSub:
                firstLotPerformanceAfterTimerange.balanceAtSubDate,
              balanceAtSubDate: pastBalancesByTimerange[timerange],
              hp:
                (firstLotPerformanceAfterTimerange.balanceAtSubDate -
                  pastBalancesByTimerange[timerange]) /
                pastBalancesByTimerange[timerange],
              lastUpdatedOn: pastDatesByTimerange[timerange],
            };

            return (
              [timerangeLot]
                .concat(lotPerformancesAfterTimerange)
                .reduce((accum, curr) => accum * (1 + curr.hp), 1) - 1
            );
          }
          return (
            (sumCurrentBalances - pastBalancesByTimerange[timerange]) /
            pastBalancesByTimerange[timerange]
          );
        }
        return null;
      };

      // const calculateAllFundsTWRSinceTimerange = (timerange) => {
      //   if (timerange === "ALL") {
      //     return lotPerformanceAllFunds[0].twr - 1;
      //   }
      //   if (!!pastBalancesByTimerange[timerange]) {
      //     const lotPerformancesAfterTimerange = lotPerformanceAllFunds.filter(
      //       (lot) => lot.lastUpdatedOn > pastDatesByTimerange[timerange]
      //     );
      //     if (lotPerformancesAfterTimerange.length > 0) {
      //       const firstLotPerformanceAfterTimerange =
      //         lotPerformancesAfterTimerange[0];

      //       const timerangeLot = {
      //         balanceAtNextSub:
      //           firstLotPerformanceAfterTimerange.balanceAtSubDate,
      //         balanceAtSubDate: pastBalancesByTimerange[timerange],
      //         hp:
      //           (firstLotPerformanceAfterTimerange.balanceAtSubDate -
      //             pastBalancesByTimerange[timerange]) /
      //           pastBalancesByTimerange[timerange],
      //         lastUpdatedOn: pastDatesByTimerange[timerange],
      //       };

      //       return (
      //         [timerangeLot]
      //           .concat(lotPerformancesAfterTimerange)
      //           .reduce((accum, curr) => accum * (1 + curr.hp), 1) - 1
      //       );
      //     }
      //     return (
      //       (sumCurrentBalances - pastBalancesByTimerange[timerange]) /
      //       pastBalancesByTimerange[timerange]
      //     );
      //   }
      //   return null;
      // };

      setPerformance(
        ["1d", "7d", "1m", "3m", "12m", "YTD", "ALL"].reduce(
          (obj, timerange) => {
            obj[timerange] = calculateDollarWeightedReturnsSinceTimerange(
              timerange
            );
            // obj[timerange] = calculateAllFundsTWRSinceTimerange(timerange);
            return obj;
          },
          {}
        )
      );
    }
  }, [historicalSumBalances, lotPerformanceAllFunds, sumCurrentBalances, txs]);

  if (isMediumScreenOrSmaller) {
    return (
      <MediumScreenAndSmallerPerformance
        performance={performance}
        activeTimerange={activeTimerange}
      />
    );
  }
  return sumCurrentBalances &&
    Object.values(performance).filter((val) => !!val).length > 0 ? (
    <LargeScreenAndLargerPerformance performance={performance} />
  ) : (
    <div className={classes.performanceList}>
      {[...Array(3)].map((empty, i) => (
        <div className={classes.performanceItem} key={i}>
          <Skeleton animation="wave" height={12} width={10} />
          <Skeleton animation="wave" height={24} width={45} />
        </div>
      ))}
    </div>
  );
}

Performance.propTypes = propTypes;
Performance.defaultProps = defaultProps;
