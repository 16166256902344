import { getFromCastleAPI, patchToCastleAPI } from "../util/api-util";
import { RECEIVE_USER_PROFILE } from "../constants/actions";

export const getUserProfile = (userId, accessToken) => (dispatch) =>
  getFromCastleAPI(`accounts/${userId}/profile`, accessToken)
    .then((response) =>
      dispatch(receiveUserProfile(response.data.data.profile))
    )
    .catch((error) => {
      console.log("[getUserProfile] error: ", error);
      throw error;
    });

export const receiveUserProfile = (data) => ({
  type: RECEIVE_USER_PROFILE,
  data,
});

export const updateUserProfile = (update, userId, accessToken) => (dispatch) =>
  patchToCastleAPI(`accounts/${userId}/profile`, update, accessToken)
    .then((response) =>
      dispatch(receiveUserProfile(response.data.data.profile))
    )
    .catch((error) => {
      console.log("[updateUserProfile] error: ", error);
      throw error;
    });
