import { RESET_DEFAULTS, RECEIVE_BALANCES } from "../constants/actions";

const initialState = [];

// {
// fundId: String
// balance: Number
// fee: Number
// totalInvestment: Number
// inceptionDate: String ISO-8601
// monthStartBalance: Number
// yearStartBalance: Number
// }

const balancesReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_BALANCES:
      if (action.userId === "5e8dfd8a0cea77650ef78d50") {
        //NOTE: Okta profile can be used with custom field i.e. 'originalInvestment' if more than one use case applies
        return action.data.map((fundBalance) =>
          fundBalance.fundId === "004cb3674d314e7d9e08e8540b0ace4c"
            ? { ...fundBalance, totalInvestment: 400000 }
            : fundBalance
        );
      }
      return action.data;
    case RESET_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default balancesReducer;
