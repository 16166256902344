import {
  CALCULATE_LOT_PERFORMANCE_BY_FUND_ID,
  CALCULATE_LOT_PERFORMANCE_SUM_FUNDS,
} from "../constants/actions";

export const calculateLotPerformanceByFundId = (
  fundId,
  fundTxs,
  fundBalance,
  historicalFundBalances
) => ({
  type: CALCULATE_LOT_PERFORMANCE_BY_FUND_ID,
  fundId,
  fundTxs,
  fundBalance,
  historicalFundBalances,
});

export const calculateLotPerformanceSumFunds = (
  allTxs,
  sumCurrentBalances,
  historicalSumBalances
) => ({
  type: CALCULATE_LOT_PERFORMANCE_SUM_FUNDS,
  allTxs,
  sumCurrentBalances,
  historicalSumBalances,
});
