import React from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { TextField as FormikTextField } from "formik-material-ui";
import { Button, Grid, Typography } from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";
import { changePassword } from "../actions/security";
import { Alert } from "@material-ui/lab";

const propTypes = {
  classes: PropTypes.object.isRequired,
};

const defaultProps = {};

const initialStatus = {
  severity: null,
  message: null,
};

export default function SettingsPersonalDetails({ classes }) {
  const { authState } = useOktaAuth();

  const user = useSelector((state) => state.user);

  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
      }}
      enableReinitialize
      initialStatus={initialStatus}
      validationSchema={Yup.object({
        oldPassword: Yup.string().required("Required"),
        newPassword: Yup.string()
          .min(6, "Must be at least 6 characters long")
          .required("Required"),
        newPasswordConfirm: Yup.string()
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
          .required("Required"),
      })}
      onSubmit={(values, { setStatus, resetForm }) => {
        changePassword(
          user.id,
          values.oldPassword,
          values.newPassword,
          authState.accessToken.value
        )
          .then((response) => {
            resetForm();
            setStatus({
              message: "Information saved.",
              severity: "success",
            });
            setTimeout(() => setStatus(initialStatus), 5000);
          })
          .catch((error) => {
            resetForm();
            let message = error.response.data.message;
            message = message.toLowerCase();
            if (message.includes("old password is required")) {
                message = "Old Password is required";
            } else if (message.includes("new password is required")) {
                message = "New Password is required";
            } else if (message.includes("confirm new password is required")) {
                message = "Confirm New Password is required";
            } else if (message.includes("old password is not correct")) {
                message = "Old Password is not correct";
            } else if (message.includes("new password is the same as old password")) {
                message = "New password is the same as old password";
            } else if (message.includes("password cannot be your current password")) {
                message = "Password cannot be your current password";
            } else if (message.includes("password requirements were not met")) {
              message = "Password requirements were not met. Password requirements: at least 8 characters, a lowercase letter, an uppercase letter, a number";
            } else {
                message = "An error occurred. Please check your info and try again";
            }
            setStatus({
              message: message,
              severity: "error",
            });
            setTimeout(() => setStatus(initialStatus), 5000);
          });
      }}
    >
      {({ isSubmitting, values, status }) => (
        <Form className={classes.form}>
          <Grid container>
            <Grid item xs={12} md={5}>
              <Typography className={classes.header} variant="h5">
                Change Password
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    type="password"
                    name="oldPassword"
                    label="Old Password"
                    component={FormikTextField}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    type="password"
                    name="newPassword"
                    label="New Password"
                    component={FormikTextField}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    type="password"
                    name="newPasswordConfirm"
                    label="Confirm New Password"
                    component={FormikTextField}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    className="button-gillsans"
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                </Grid>
                <Grid item xs={12} md={9}>
                  {status.severity && (
                    <Alert severity={status.severity}>{status.message}</Alert>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

SettingsPersonalDetails.propTypes = propTypes;
SettingsPersonalDetails.defaultProps = defaultProps;
