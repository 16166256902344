import IowanRomanWoff from "./IowanOldStyle-Roman.woff";
import IowanRomanWoff2 from "./IowanOldStyle-Roman.woff2";
import IowanBTWoff from "./IowanOldStyleBT-Italic.woff";
import IowanBTWoff2 from "./IowanOldStyleBT-Italic.woff2";
import IowanBoldWoff from "./IowanOldStyle-Bold.woff";
import IowanBoldWoff2 from "./IowanOldStyle-Bold.woff2";
import IowanItalicWoff from "./IowanOldStyle-Italic.woff";
import IowanItalicWoff2 from "./IowanOldStyle-Italic.woff2";
import IowanTitlingWoff from "./IowanOldStyle-Titling.woff";
import IowanTitlingWoff2 from "./IowanOldStyle-Titling.woff2";
import IowanBlackWoff from "./IowanOldStyle-Black.woff";
import IowanBlackWoff2 from "./IowanOldStyle-Black.woff2";
import IowanBlackItalicWoff from "./IowanOldStyle-BlackItalic.woff";
import IowanBlackItalicWoff2 from "./IowanOldStyle-BlackItalic.woff2";
import GillSansWoff from "./GillSansStd.woff";
import GillSansWoff2 from "./GillSansStd.woff2";
import GillSansCondensedWoff from "./GillSansStd-Condensed.woff";
import GillSansCondensedWoff2 from "./GillSansStd-Condensed.woff2";
import GillSansBoldWoff from "./GillSansStd-Bold.woff";
import GillSansBoldWoff2 from "./GillSansStd-Bold.woff2";
import GillSansExtraBoldDispWoff from "./GillSansStd-ExtraBoldDisp.woff";
import GillSansExtraBoldDispWoff2 from "./GillSansStd-ExtraBoldDisp.woff2";
import GillSansExtraBoldWoff from "./GillSansStd-ExtraBold.woff";
import GillSansExtraBoldWoff2 from "./GillSansStd-ExtraBold.woff2";
import GillSansLightItalicWoff from "./GillSansStd-LightItalic.woff";
import GillSansLightItalicWoff2 from "./GillSansStd-LightItalic.woff2";
import GillSansLightShadowedWoff from "./GillSansStd-LightShadowed.woff";
import GillSansLightShadowedWoff2 from "./GillSansStd-LightShadowed.woff2";
import GillSansItalicWoff from "./GillSansStd-Italic.woff";
import GillSansItalicWoff2 from "./GillSansStd-Italic.woff2";
import GillSansUltraBoldCondWoff from "./GillSansStd-UltraBoldCond.woff";
import GillSansUltraBoldCondWoff2 from "./GillSansStd-UltraBoldCond.woff2";
import GillSansBoldItalicWoff from "./GillSansStd-BoldItalic.woff";
import GillSansBoldItalicWoff2 from "./GillSansStd-BoldItalic.woff2";
import GillSansUltraBoldWoff from "./GillSansStd-UltraBold.woff";
import GillSansUltraBoldWoff2 from "./GillSansStd-UltraBold.woff2";
import GillSansExtraBoldCondWoff from "./GillSansStd-BoldExtraCond.woff";
import GillSansExtraBoldCondWoff2 from "./GillSansStd-BoldExtraCond.woff2";
import GillSansShadowedWoff from "./GillSansStd-Shadowed.woff";
import GillSansShadowedWoff2 from "./GillSansStd-Shadowed.woff2";
import GillSansBoldCondWoff from "./GillSansStd-BoldCondensed.woff";
import GillSansBoldCondWoff2 from "./GillSansStd-BoldCondensed.woff2";
import GillSansLightWoff from "./GillSansStd-Light.woff";
import GillSansLightWoff2 from "./GillSansStd-Light.woff2";

export const iowanRoman = {
  fontFamily: "Iowan Old Style",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Iowan Old Style'),
    url(${IowanRomanWoff}) format('woff'),
    url(${IowanRomanWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const iowanBT = {
  fontFamily: "Iowan Old Style",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `
    url(${IowanBTWoff}) format('woff'),
    url(${IowanBTWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const iowanBold = {
  fontFamily: "Iowan Old Style",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
    url(${IowanBoldWoff}) format('woff'),
    url(${IowanBoldWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const iowanItalic = {
  fontFamily: "Iowan Old Style",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    url(${IowanItalicWoff}) format('woff'),
    url(${IowanItalicWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const iowanTitling = {
  fontFamily: "Iowan Old Style",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
    url(${IowanTitlingWoff}) format('woff'),
    url(${IowanTitlingWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const iowanBlack = {
  fontFamily: "Iowan Old Style",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 900,
  src: `
    url(${IowanBlackWoff}) format('woff'),
    url(${IowanBlackWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const iowanBlackItalic = {
  fontFamily: "Iowan Old Style",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 900,
  src: `
    url(${IowanBlackItalicWoff}) format('woff'),
    url(${IowanBlackItalicWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSans = {
  fontFamily: "Gill Sans Std",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Gill Sans'),
    local('Gill Sans Std'),
    url(${GillSansWoff}) format('woff'),
    url(${GillSansWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansCondensed = {
  fontFamily: "Gill Sans Std",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
    url(${GillSansCondensedWoff}) format('woff'),
    url(${GillSansCondensedWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansBold = {
  fontFamily: "Gill Sans Std",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 300,
  src: `
    url(${GillSansBoldWoff}) format('woff'),
    url(${GillSansBoldWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansExtraBoldDisp = {
  fontFamily: "Gill Sans Std",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 900,
  src: `
    url(${GillSansExtraBoldDispWoff}) format('woff'),
    url(${GillSansExtraBoldDispWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansExtraBold = {
  fontFamily: "Gill Sans Std",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 800,
  src: `
    url(${GillSansExtraBoldWoff}) format('woff'),
    url(${GillSansExtraBoldWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansLightItalic = {
  fontFamily: "Gill Sans Std",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 200,
  src: `
    url(${GillSansLightItalicWoff}) format('woff'),
    url(${GillSansLightItalicWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansLightShadowed = {
  fontFamily: "Gill Sans Std",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 200,
  src: `
    url(${GillSansLightShadowedWoff}) format('woff'),
    url(${GillSansLightShadowedWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansItalic = {
  fontFamily: "Gill Sans Std",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    url(${GillSansItalicWoff}) format('woff'),
    url(${GillSansItalicWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansUltraBoldCond = {
  fontFamily: "Gill Sans Std",
  fontStyle: "oblique",
  fontDisplay: "swap",
  fontWeight: 900,
  src: `
    url(${GillSansUltraBoldCondWoff}) format('woff'),
    url(${GillSansUltraBoldCondWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansBoldItalic = {
  fontFamily: "Gill Sans Std",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 300,
  src: `
    url(${GillSansBoldItalicWoff}) format('woff'),
    url(${GillSansBoldItalicWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansUltraBold = {
  fontFamily: "Gill Sans Std",
  fontStyle: "oblique",
  fontDisplay: "swap",
  fontWeight: 800,
  src: `
    url(${GillSansUltraBoldWoff}) format('woff'),
    url(${GillSansUltraBoldWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansExtraBoldCond = {
  fontFamily: "Gill Sans Std",
  fontStyle: "oblique",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `
    url(${GillSansExtraBoldCondWoff}) format('woff'),
    url(${GillSansExtraBoldCondWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansShadowed = {
  fontFamily: "Gill Sans Std",
  fontStyle: "oblique",
  fontDisplay: "swap",
  fontWeight: 200,
  src: `
    url(${GillSansShadowedWoff}) format('woff'),
    url(${GillSansShadowedWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansBoldCond = {
  fontFamily: "Gill Sans Std",
  fontStyle: "oblique",
  fontDisplay: "swap",
  fontWeight: 300,
  src: `
    url(${GillSansBoldCondWoff}) format('woff'),
    url(${GillSansBoldCondWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const gillSansLight = {
  fontFamily: "Gill Sans Std",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 100,
  src: `
    url(${GillSansLightWoff}) format('woff'),
    url(${GillSansLightWoff2}) format('woff2'),
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
