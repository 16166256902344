import { getFromCastleAPI } from "../util/api-util";
import {
  RECEIVE_FUND_INFO,
  SET_SHOW_FUND_HOLDINGS_DISCLAIMER,
} from "../constants/actions";

export const getFundInfo = (fundId, accessToken) => (dispatch) =>
  getFromCastleAPI(`funds/${fundId}`, accessToken)
    .then((response) => dispatch(receiveFundInfo(fundId, response.data.data)))
    .catch((error) => {
      console.log("[getFundInfo] error: ", error);
      throw error;
    });

export const receiveFundInfo = (fundId, data) => ({
  type: RECEIVE_FUND_INFO,
  fundId,
  data,
});

//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\\

export const setShowFundHoldingsDisclaimer = (
  isShowDisclaimer,
  userAcknowledged
) => (dispatch) =>
  dispatch({
    isShowDisclaimer,
    userAcknowledged,
    type: SET_SHOW_FUND_HOLDINGS_DISCLAIMER,
  });
