import {
  RESET_DEFAULTS,
  RECEIVE_HISTORICAL_BALANCES,
} from "../constants/actions";
import merge from "lodash/merge";

const initialState = {};

// fundId: [{
// timestamp: String ISO-8601
// fundId: String
// uid: String
// balance: Number
// }]

const historicalBalancesReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_HISTORICAL_BALANCES:
      return merge({}, state, { [action.fundId]: action.data });
    case RESET_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default historicalBalancesReducer;
