import React, { useState, useEffect } from "react";
import {
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";
import PortfolioCard from "./PortfolioCard";
import PortfolioTable from "./PortfolioTable";

const propTypes = {};

const defaultProps = {};

export default function Portfolio() {
  const theme = useTheme();

  const isMediumScreenAndSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const balances = useSelector((state) => state.balances);
  const funds = useSelector((state) => state.funds);
  const txs = useSelector((state) => state.txs);

  const [lastUpdated, setLastUpdated] = useState(null);
  const [fundBalancesWithTxs, setFundBalancesWithT] = useState([{}]);

  useEffect(() => {
    if (balances.length > 0 && txs.length > 0) {
      const balancesWithTxs = balances.map((balance) => ({
        ...balance,
        name: funds[balance.fundId] ? funds[balance.fundId].name : "",
        totalSubscriptions: txs
          .filter(
            (tx) =>
              tx.indexId === balance.fundId &&
              tx.type === "SUBSCRIPTION" &&
              tx.state === "COMPLETE"
          )
          .reduce((a, b) => a + b.amount, 0),
        totalRedemptions: txs
          .filter(
            (tx) =>
              tx.indexId === balance.fundId &&
              tx.type === "WITHDRAWAL" &&
              tx.state === "COMPLETE"
          )
          .reduce((a, b) => a + b.amount, 0),
      }));
      setFundBalancesWithT(balancesWithTxs);
      setLastUpdated(moment().format("MMMM D, YYYY h:mm:ss a"));
    }
  }, [balances, txs, funds]);

  return isMediumScreenAndSmaller ? (
    <React.Fragment>
      {fundBalancesWithTxs.map((fund, i) => (
        <PortfolioCard key={i} fund={fund} />
      ))}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <PortfolioTable
        lastUpdated={lastUpdated}
        fundBalancesWithTxs={fundBalancesWithTxs}
      />
    </React.Fragment>
  );
}

Portfolio.propTypes = propTypes;
Portfolio.defaultProps = defaultProps;
