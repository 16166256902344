import { RESET_DEFAULTS, RECEIVE_FUND_INFO } from "../constants/actions";
import merge from "lodash/merge";

const initialState = {};

// fundId: [{
// id: String
// uid: String
// name: String
// fundType: String
// description: String
// objective: String
// createdOn: String ISO-8601
// inception: String ISO-8601
// active: Boolean
// }]

const fundsReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_FUND_INFO:
      return merge({}, state, { [action.fundId]: action.data });
    case RESET_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default fundsReducer;
