import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {useOktaAuth} from "@okta/okta-react";
import DocumentItem from "./DocumentItem";
import {
    Divider, FormControl, Grid,
    InputAdornment,
    List,
    ListItem,
    makeStyles, MenuItem, Select,
    TextField,
    Typography,
} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import SearchIcon from "@material-ui/icons/Search";
import {getDocuments, getDocumentsWithOffset} from "../actions/documents";
import {DOCUMENT_TYPE_GENERAL, DOCUMENT_TYPE_ONBOARDING, DOCUMENT_TYPE_TAX} from "../constants/routes";
import {checkStatusAndLogOutOn401, getErrorMessage} from "../util/helpers";
import {ERROR_SNACKBAR} from "../constants/properties";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    buttonWrapper: {
        // position: "relative",
        [theme.breakpoints.down("xs")]: {
            alignSelf: "center",
            //marginTop: "15px",
            marginTop: "50px",
        },
    },
    controlsButtons: {
        justifyContent: "flex-end",
        marginRight: theme.spacing(6),
        //backgroundColor: "pink"
    },
    controlsDivider: {
        marginRight: theme.spacing(5),
    },
    disabledLink: {
        color: theme.palette.grey.dark,
        textDecoration: "none"
    },
    documentsList: {
    },
    doc: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        // margin: theme.spacing(3),
        // "&:first-of-type": {
        //   marginTop: 0,
        // },
        [theme.breakpoints.down("xs")]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    docDivider: {
        marginRight: theme.spacing(5),
    },
    docIcon: {
        height: 25,
        display: "block",
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginRight: 0,
            marginBottom: theme.spacing(1),
        },
    },
    docLeft: {
        marginLeft: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            margin: "0 auto",
            textAlign: "center",
        },
    },
    docTitle: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            marginRight: 0,
        },
    },
    empty: {
        textAlign: "center",
        margin: "0 auto",
        maxWidth: 250,
        marginTop: theme.spacing(20),
    },
    gridControls: {
        justifyContent: "flex-end",
        textAlign: "right",
        marginBottom: theme.spacing(4),
    },
    gridRoot: {
        flexGrow: 1
    },
    headerInputs: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    link: {
        color: theme.palette.grey.dark,
        fontWeight: "bold"
    },
    linkSpan: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    listItem: {
        alignItems: "flex-start",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
    rowsSelectorForm: {
    },
    rowsSelectorList: {
        marginTop: theme.spacing(1.2),
        verticalAlign: "middle",
        "& .MuiFilledInput-input": {
            backgroundColor: "white",
            //marginTop: "5px",
            verticalAlign: "middle",
            paddingTop: "5px",
            paddingBottom: "5px",
        }
    },
    search: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: "100%",
    },
    subtitle: {
        color: theme.palette.grey.dark,
    },
    tabContentTitle: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(4),
    }
}));

const propTypes = {
    parameters: PropTypes.object.isRequired,
};

const defaultProps = {};

export default function DocumentsList({parameters}) {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const documentType = parameters.documentType;
    const {authState} = useOktaAuth();
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector((state) => state.user);
    const clientId = user.id;
    const documents =
        useSelector((state) => {
            if (state.documents["data"]) {
                return state.documents.data.documents;
            } else {
                return [];
            }
        }) || [];
    const links =
        useSelector((state) => {
            if (state.documents["data"]) {
                return state.documents["_links"];
            } else {
                return {};
            }
        }) || [];
    const counts =
        useSelector((state) => {
            if (state.documents["data"]) {
                return {
                    count: state.documents.data.count,
                    totalCount: state.documents.data.totalCount
                };
            } else {
                return {
                    count: 0,
                    totalCount: 0
                };
            }
        }) || {
            count: 0,
            totalCount: 0
        };

    const [filterString, setFilterString] = useState("");
    const [linkUrl, setLinkUrl] = useState(null);
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const [previousDocumentType, setPreviousDocumentType] = useState(parameters.documentType);

    const getSectionTitle = (docType) => {
        if (docType === DOCUMENT_TYPE_GENERAL) {
            return "Investor Letters & Briefings";
        } else if (docType === DOCUMENT_TYPE_TAX) {
            return "Schedule K-1s & Tax Forms";
        } else if (docType === DOCUMENT_TYPE_ONBOARDING) {
            return "LP Documents";
        } else {
            return "Account Statements";
        }
    }

    const onClickNavigationLink = (event) => {
        const id = event.target.id;
        //console.log("onClickNavigationLink, id/linkUrl", id, linkUrl);
        // documents are sorted by date from the newest to the oldest so the "next" batch will load older documents
        // while the previous batch will load "newer" documents. This is the reason for the inversion of what
        // we pass to the api vs. what we show in the UI
        switch (id) {
            case "oldest":
                if (links.last) {
                    setLinkUrl(links.last);
                    const newPage = Math.ceil(counts.totalCount / count);
                    setPage(newPage);
                }
                break;
            case "newest":
                setLinkUrl(null);
                setPage(1);
                break;
            case "next":
                if (links.next) {
                    setLinkUrl(links.next);
                    setPage(page + 1);
                }
                break;
            case "prev":
                if (links.prev) {
                    setLinkUrl(links.prev);
                    const newPage = page - 1;
                    setPage((newPage > 0) ? newPage : 1);
                }
                break;
            default: // ignore
                break;
        }
    }

    const handleRowsSelectorChange = (event) => {
        //console.log("handleRowsSelectorChange", event);
        setLinkUrl(null);
        setPage(1);
        setCount(event.target.value);
    };

    const getPageControlLabel = (documentsCount, totalCount, currentPage, rowCount) => {
        const startRow = (currentPage - 1) * rowCount + 1;
        const pageByRowCount = currentPage * rowCount;
        const endRow = (pageByRowCount > totalCount) ? totalCount : pageByRowCount;
        return startRow + "-" + endRow + " of " + totalCount;
    }

    useEffect(() => {
        if (clientId !== undefined && clientId !== null) {
            const link = (documentType === previousDocumentType) ? linkUrl : null;
            if (documentType !== previousDocumentType) {
                setPreviousDocumentType(documentType);
            }

            if (link) {
                dispatch(getDocumentsWithOffset(clientId, linkUrl, documentType, authState.accessToken.accessToken)).catch(
                    (error) => {
                        checkStatusAndLogOutOn401(error, history);
                        return enqueueSnackbar(getErrorMessage(error, ERROR_SNACKBAR));
                    }
                );
            } else {
                setPage(1);
                setLinkUrl(null);
                //userId, count, fundId, documentType, textSearch, accessToken
                dispatch(getDocuments(clientId, count, null, documentType, filterString, authState.accessToken.accessToken)).catch(
                    (error) => {
                        checkStatusAndLogOutOn401(error, history);
                        return enqueueSnackbar(getErrorMessage(error, ERROR_SNACKBAR));
                    }
                );
            }
        }
    }, [clientId, count, page, parameters, filterString, linkUrl, previousDocumentType, authState, dispatch]);

    if (documents.length === 0) {
        return (
            <React.Fragment>
                <Grid container className={classes.gridRoot}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5"
                                    className={classes.tabContentTitle}>{getSectionTitle(documentType)}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <div className={classes.headerInputs}>
                            <TextField
                                label="Search Documents"
                                type="search"
                                variant="standard"
                                className={classes.search}
                                onChange={(event) => {
                                    setFilterString(event.target.value);
                                    setLinkUrl(null);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className={classes.empty}>
                            <FolderIcon/>
                            <Typography variant="body1">
                                Documents will be shown here once they are made available.
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <Grid container className={classes.gridRoot}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5"
                                    className={classes.tabContentTitle}>{getSectionTitle(documentType)}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <div className={classes.headerInputs}>
                            <TextField
                                label="Search Documents"
                                type="search"
                                variant="standard"
                                className={classes.search}
                                onChange={(event) => {
                                    setFilterString(event.target.value);
                                    setLinkUrl(null);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <List className={classes.documentsList}>
                            {documents.map((doc, i) => (
                                <React.Fragment key={doc.id}>
                                    <ListItem className={classes.listItem}>
                                        <DocumentItem
                                            doc={doc}
                                            classes={classes}
                                        />
                                    </ListItem>
                                    {i < documents.length - 1 && <Divider className={classes.docDivider}/>}
                                </React.Fragment>
                            ))}
                        </List>
                        <Grid container className={classes.gridControls}>
                            <Grid item xs={12} md={12} className={classes.controlsDivider}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.controlsButtons}>
                                <FormControl className={classes.rowsSelector}>
                                    <Select
                                        labelId="rows-select-label"
                                        id="rows-select"
                                        value={count}
                                        onChange={handleRowsSelectorChange}
                                        variant={"filled"}
                                        className={classes.rowsSelectorList}
                                    >
                                        <MenuItem value={5}>5 rows</MenuItem>
                                        <MenuItem value={10}>10 rows</MenuItem>
                                        <MenuItem value={20}>20 rows</MenuItem>
                                    </Select>
                                </FormControl>
                                <span title="First Page" className="">
                                    {(links && links.first) ?
                                        (
                                            <button className="MuiButtonBase-root MuiIconButton-root" tabIndex="0"
                                                    type="button"
                                                    onClick={onClickNavigationLink}>
                                                <span className="MuiIconButton-label">
                                                    <span id={"newest"} className="material-icons MuiIcon-root"
                                                          aria-hidden="true">first_page</span>
                                                </span>
                                                <span className="MuiTouchRipple-root"></span>
                                            </button>
                                        ) :
                                        (
                                            <button
                                                className="MuiButtonBase-root MuiIconButton-root Mui-disabled Mui-disabled"
                                                tabIndex="-1" type="button" disabled="">
                                            <span className="MuiIconButton-label">
                                                <span className="material-icons MuiIcon-root"
                                                      aria-hidden="true">first_page</span>
                                            </span>
                                                <span className="MuiTouchRipple-root"></span>
                                            </button>
                                        )
                                    }
                                </span>
                                <span title="Previous Page" className="">
                                    {(links && links.prev) ?
                                        (
                                            <button className="MuiButtonBase-root MuiIconButton-root" tabIndex="0"
                                                    type="button"
                                                    onClick={onClickNavigationLink}>
                                                <span className="MuiIconButton-label">
                                                    <span id={"prev"} className="material-icons MuiIcon-root"
                                                          aria-hidden="true">chevron_left</span>
                                                </span>
                                                <span className="MuiTouchRipple-root"></span>
                                            </button>
                                        ) :
                                        (
                                            <button
                                                className="MuiButtonBase-root MuiIconButton-root Mui-disabled Mui-disabled"
                                                tabIndex="-1" type="button" disabled="">
                                            <span className="MuiIconButton-label">
                                                <span className="material-icons MuiIcon-root"
                                                      aria-hidden="true">chevron_left</span>
                                            </span>
                                                <span className="MuiTouchRipple-root"></span>
                                            </button>
                                        )
                                    }
                                </span>

                                <Typography variant="caption"
                                            className={classes.disabledLink}>{getPageControlLabel(documents.length, counts.totalCount, page, count)}</Typography>

                                <span title="Next Page" className="">
                                    {(links && links.next) ?
                                        (
                                            <button className="MuiButtonBase-root MuiIconButton-root" tabIndex="0"
                                                    type="button"
                                                    onClick={onClickNavigationLink}>
                                                <span className="MuiIconButton-label">
                                                    <span id={"next"} className="material-icons MuiIcon-root"
                                                          aria-hidden="true">chevron_right</span>
                                                </span>
                                                <span className="MuiTouchRipple-root"></span>
                                            </button>
                                        ) :
                                        (
                                            <button
                                                className="MuiButtonBase-root MuiIconButton-root Mui-disabled Mui-disabled"
                                                tabIndex="-1" type="button" disabled="">
                                            <span className="MuiIconButton-label">
                                                <span className="material-icons MuiIcon-root"
                                                      aria-hidden="true">chevron_right</span>
                                            </span>
                                                <span className="MuiTouchRipple-root"></span>
                                            </button>
                                        )
                                    }
                                </span>

                                <span title="Last Page" className="">
                                    {(links && links.last) ?
                                        (
                                            <button className="MuiButtonBase-root MuiIconButton-root" tabIndex="0"
                                                    type="button"
                                                    onClick={onClickNavigationLink}>
                                                <span className="MuiIconButton-label">
                                                    <span id={"oldest"} className="material-icons MuiIcon-root"
                                                          aria-hidden="true">last_page</span>
                                                </span>
                                                <span className="MuiTouchRipple-root"></span>
                                            </button>
                                        ) :
                                        (
                                            <button
                                                className="MuiButtonBase-root MuiIconButton-root Mui-disabled Mui-disabled"
                                                tabIndex="-1" type="button" disabled="">
                                            <span className="MuiIconButton-label">
                                                <span className="material-icons MuiIcon-root"
                                                      aria-hidden="true">last_page</span>
                                            </span>
                                                <span className="MuiTouchRipple-root"></span>
                                            </button>
                                        )
                                    }
                                </span>
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.controlsDivider}>
                                <Divider/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

DocumentsList.propTypes = propTypes;
DocumentsList.defaultProps = defaultProps;
