import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalize,
  datestringize,
  dollarize,
  percentize,
} from "../util/helpers";
import { Skeleton } from "@material-ui/lab";
import { calculateLotPerformanceByFundId } from "../actions/performance";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardDivider: {
    width: "50%",
    margin: "0 auto",
  },
  cardTitle: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(0),
  },
  lastUpdated: {
    fontStyle: "italic",
    color: theme.palette.grey.dark,
    marginLeft: theme.spacing(2),
  },
  positive: {
    color: theme.palette.deltaGreen.main,
  },
  property: {
    fontWeight: 300,
  },
  negative: {
    color: theme.palette.deltaRed.main,
  },
  zero: {
    color: "initial",
  },
}));

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row({ row }) {
  const classes = useRowStyles();
  const dispatch = useDispatch();
  const allTxs = useSelector((state) => state.txs);
  const allHistoricalBalances = useSelector(
    (state) => state.historicalBalances
  );
  const lotPerformanceByFundId = useSelector(
    (state) => state.lotPerformanceByFundId
  );

  const [open, setOpen] = useState(true);

  useEffect(() => {
    const fundHistoricalBalances = allHistoricalBalances[row.fundId] || [];
    const fundTxs = allTxs.filter(
      (tx) => tx.indexId === row.fundId && tx.state === "COMPLETE"
    );
    if (
      !!row.fundId &&
      fundTxs.length > 0 &&
      !!row.balance &&
      fundHistoricalBalances.length > 0
    )
      dispatch(
        calculateLotPerformanceByFundId(
          row.fundId,
          fundTxs,
          row.balance,
          fundHistoricalBalances
        )
      );
  }, [allTxs, allHistoricalBalances, row, dispatch]);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {!!row.name ? (
            row.name
          ) : (
            <Skeleton animation="wave" height={20} width="80%" />
          )}
        </TableCell>
        <TableCell align="right">
          {!!row.name ? (
            dollarize(row.balance)
          ) : (
            <Skeleton animation="wave" height={20} width="80%" />
          )}
        </TableCell>
        <TableCell align="right">
          {!!row.name ? (
            dollarize(row.totalSubscriptions)
          ) : (
            <Skeleton animation="wave" height={20} width="80%" />
          )}
        </TableCell>
        <TableCell align="right">
          {!!row.name ? (
            dollarize(row.totalRedemptions)
          ) : (
            <Skeleton animation="wave" height={20} width="80%" />
          )}
        </TableCell>
        <TableCell align="right">
          {!!row.name ? (
            datestringize(row.inceptionDate)
          ) : (
            <Skeleton animation="wave" height={20} width="80%" />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} paddingBottom={2}>
              <Typography variant="h5" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Return Since ($)</TableCell>
                    <TableCell align="right">Return Since (%)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!lotPerformanceByFundId[row.fundId] ? (
                    lotPerformanceByFundId[row.fundId].map((tx) => {
                      return (
                        <TableRow key={tx.id}>
                          <TableCell component="th" scope="row">
                            {datestringize(tx.lastUpdatedOn)}
                          </TableCell>
                          <TableCell>
                            {tx.type === "WITHDRAWAL"
                              ? "Redemption"
                              : capitalize(tx.type)}
                          </TableCell>
                          <TableCell align="right">
                            {dollarize(tx.amount)}
                          </TableCell>
                          <TableCell align="right">
                            {dollarize(tx.amount * (tx.twr - 1))}
                          </TableCell>
                          <TableCell align="right">
                            {percentize(tx.twr - 1)}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" height={20} width="80%" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" height={20} width="80%" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton animation="wave" height={20} width="100%" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton animation="wave" height={20} width="100%" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton animation="wave" height={20} width="100%" />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string,
    balance: PropTypes.number,
    fee: PropTypes.number,
    fundId: PropTypes.string,
    inceptionDate: PropTypes.string,
    monthStartBalance: PropTypes.number,
    totalInvestment: PropTypes.number,
    totalRedemptions: PropTypes.number,
    totalSubscriptions: PropTypes.number,
    yearStartBalance: PropTypes.number,
  }).isRequired,
};

const propTypes = {
  fundBalancesWithTxs: PropTypes.array.isRequired,
  lastUpdated: PropTypes.string,
};

export default function PortfolioTable({ fundBalancesWithTxs, lastUpdated }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <div className={classes.header}>
        <Typography variant="h5">Portfolio</Typography>
        {!!lastUpdated && (
          <Typography className={classes.lastUpdated} variant="body2">
            Last Updated: {lastUpdated}
          </Typography>
        )}
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Fund</TableCell>
            <TableCell align="right">Current Nav</TableCell>
            <TableCell align="right">Total Subscriptions</TableCell>
            <TableCell align="right">Total Redemptions</TableCell>
            <TableCell align="right">Investor Since</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fundBalancesWithTxs.map((fund, i) => (
            <Row key={i} row={fund} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

PortfolioTable.propTypes = propTypes;
