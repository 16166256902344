import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHistoricalBalances,
  sumHistoricalBalances,
} from "../actions/balances";
import {
  getErrorMessage,
  dollarize,
  datestringizeWithTime,
} from "../util/helpers";
import Chart from "react-apexcharts";
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  useTheme,
  useMediaQuery,
  Button,
  Box,
  makeStyles,
} from "@material-ui/core";
import Performance from "./Performance";
import ReactGA from "react-ga";
import { useOktaAuth } from "@okta/okta-react";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "inherit",
    fontFamily: "Gill Sans Std",
    fontWeight: 400,
  },
  buttonActive: {
    fontFamily: "Gill Sans Std",
    fontWeight: 300,
    color: theme.palette.gold.main,
  },
}));

const propTypes = {};

const defaultProps = {};

export default function BalancesChart() {
  const classes = useStyles();
  const theme = useTheme();
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();

  const isMediumScreenAndSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const balances = useSelector((state) => state.balances);
  const historicalBalances = useSelector((state) => state.historicalBalances);
  const historicalSumBalances = useSelector(
    (state) => state.historicalSumBalances
  );
  const user = useSelector((state) => state.user);

  const [data, setData] = useState([]);
  const [timerange, setTimerange] = useState({ start: null, active: "ALL" });

  useEffect(() => {
    // Check to see if any new fund has arisen since the last time we pulled balances.
    // If so, get historical balances for the new fund & store in redux state with existing funds
    balances
      .filter((fund) => !historicalBalances.hasOwnProperty(fund.fundId))
      .map((fund) => {
        console.log(
          "[BalancesChart] getHistoricalBalances for new fund: ",
          fund
        );
        const from = fund.inceptionDate;
        const to = moment().toISOString();

        return dispatch(
          getHistoricalBalances(
            user.id,
            fund.fundId,
            from,
            to,
            authState.accessToken.value
          )
        ).catch((error) => console.log(getErrorMessage(error)));
      });
  }, [user, balances, authState, dispatch, historicalBalances]);

  useEffect(() => {
    // If we have historical balances for the same number of funds the user is in
    // dispatch those historical balances to be summed and saved for the chart and peformance section
    if (
      balances.length > 0 &&
      balances.length === Object.keys(historicalBalances).length
    ) {
      dispatch(sumHistoricalBalances(historicalBalances));
    }
  }, [balances, historicalBalances, dispatch]);

  useEffect(() => {
    // If we have summed historical balances, format the data and chart it
    if (Object.keys(historicalSumBalances).length > 0) {
      const historicalBalancesDays = Object.keys(historicalSumBalances).length;
      setData(
        Object.entries(historicalSumBalances)
          .slice(0, timerange.start || historicalBalancesDays)
          .map((snapshotArr) => ({ x: snapshotArr[0], y: snapshotArr[1] }))
          .concat({
            x: moment().toISOString(),
            y: balances
              .map((fund) => fund.balance)
              .reduce((sumBal, currBal) => sumBal + currBal, 0),
          })
      );
    }
  }, [historicalSumBalances, balances, timerange]);

  const updateTimerange = (amount, period, activeTimeframe, special) => {
    if (activeTimeframe === timerange.active) {
      return;
    }
    let startIndex;
    if (special) {
      startIndex =
        special === "YTD"
          ? moment().diff(moment().utc().startOf("year"), "days") + 1
          : null;
    } else {
      startIndex = moment().diff(moment().subtract(amount, period), "days") + 1;
    }
    setTimerange({
      start: startIndex,
      active: activeTimeframe,
    });
    ReactGA.event({
      category: "Portfolio",
      action: "Updated Timerange",
      label: activeTimeframe,
    });
  };
  return data.length > 0 ? (
    <React.Fragment>
      {isMediumScreenAndSmaller && (
        <Performance activeTimerange={timerange.active} />
      )}
      <Chart
        options={{
          chart: {
            offsetY: 0,
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            borderColor: "#E6E6E5",
            padding: {
              left: 0,
              right: 0,
              top: 0,
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          stroke: {
            curve: "smooth",
            width: 1,
          },
          tooltip: {
            style: {
              fontFamily: "Iowan Old Style, Roboto, sans-serif",
            },
            x: {
              formatter: (timestamp) => datestringizeWithTime(timestamp),
            },
            y: {
              formatter: (num) => dollarize(num),
              title: {
                formatter: (fundId) => "Portfolio Balance",
              },
            },
          },
          xaxis: {
            type: "datetime",
            tooltip: {
              enabled: false,
            },
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "MMM yyyy",
                day: "MMM dd",
                hour: "HH:mm",
              },
              style: {
                fontFamily: "Iowan Old Style, Roboto, sans-serif",
              },
            },
            formatter: (value, timestamp, index) =>
              datestringizeWithTime(timestamp),
          },
          yaxis: {
            show: false,
            tickAmount: 3,
            max: Math.max(...data.map((item) => item.y)) + 1000,
            min: Math.min(...data.map((item) => item.y)) * 0.7,
            labels: {
              formatter: (num) => dollarize(num),
              style: {
                fontFamily: "Iowan Old Style, Roboto, sans-serif",
              },
            },
          },
        }}
        series={[
          {
            name: "Portfolio Balance",
            data,
          },
        ]}
        type="area"
        width="100%"
        height={300}
      />
      {isMediumScreenAndSmaller && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            className={
              timerange.active === "1d" ? classes.buttonActive : classes.button
            }
            onClick={() => updateTimerange(0, "days", "1d")}
          >
            DAY
          </Button>
          <Button
            className={
              timerange.active === "7d" ? classes.buttonActive : classes.button
            }
            onClick={() => updateTimerange(6, "days", "7d")}
          >
            WEEK
          </Button>
          <Button
            className={
              timerange.active === "1m" ? classes.buttonActive : classes.button
            }
            onClick={() => updateTimerange(1, "months", "1m")}
          >
            MONTH
          </Button>
          <Button
            className={
              timerange.active === "YTD" ? classes.buttonActive : classes.button
            }
            onClick={() => updateTimerange(1, "years", "YTD", "YTD")}
          >
            YTD
          </Button>
          <Button
            className={
              timerange.active === "ALL" ? classes.buttonActive : classes.button
            }
            onClick={() => updateTimerange(7, "days", "ALL", "ALL")}
          >
            ALL
          </Button>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Skeleton variant="rect" height={300} animation="wave" />
      <Skeleton variant="rect" height={36} animation="wave" />
    </React.Fragment>
  );
}

BalancesChart.propTypes = propTypes;
BalancesChart.defaultProps = defaultProps;
