import { postToCastleAPI } from "../util/api-util";

export const changePassword = (userId, oldPassword, newPassword, accessToken) =>
  postToCastleAPI(
    `accounts/${userId}/security/password`,
    {
      oldPassword,
      newPassword,
    },
    accessToken
  )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
