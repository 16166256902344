import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { datestringize, dollarize } from "../util/helpers";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardDivider: {
    width: "50%",
    margin: "0 auto",
  },
  cardTitle: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
  },
  lastUpdated: {
    fontStyle: "italic",
    color: theme.palette.grey.dark,
    marginLeft: theme.spacing(2),
  },
  positive: {
    color: theme.palette.deltaGreen.main,
  },
  property: {
    fontWeight: 300,
  },
  negative: {
    color: theme.palette.deltaRed.main,
  },
  zero: {
    color: "initial",
  },
}));

const propTypes = {
  fund: PropTypes.object.isRequired,
};

const defaultProps = {};

export default function PortfolioCard({ fund }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" className={classes.cardTitle}>
          {fund.name}
        </Typography>
        <Divider className={classes.cardDivider} />
        <List>
          <ListItem>
            <ListItemText
              primary={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" className={classes.property}>
                    Current NAV
                  </Typography>
                  <Typography variant="body1">
                    {dollarize(fund.balance)}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" className={classes.property}>
                    Total Subscriptions
                  </Typography>
                  <Typography variant="body1">
                    {dollarize(fund.totalSubscriptions)}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" className={classes.property}>
                    Total Redemptions
                  </Typography>
                  <Typography variant="body1">
                    {dollarize(fund.totalRedemptions)}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
          {/* <ListItem>
              <ListItemText
                primary={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1" className={classes.property}>
                      Total Return
                    </Typography>
                    <Typography
                      variant="body1"
                      className={
                        (typeof fund.balance === "number" &&
                          typeof fund.totalInvestment === "number" &&
                          fund.balance - fund.totalInvestment) > 0
                          ? classes.positive
                          : (typeof fund.balance === "number" &&
                              typeof fund.totalInvestment === "number" &&
                              fund.balance - fund.totalInvestment) < 0
                          ? classes.negative
                          : classes.zero
                      }
                    >
                      {typeof fund.balance === "number" &&
                      typeof fund.totalInvestment === "number"
                        ? dollarize(fund.balance - fund.totalInvestment)
                        : ""}
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1" className={classes.property}>
                      YTD Return
                    </Typography>
                    <Typography
                      variant="body1"
                      className={
                        (typeof fund.balance === "number" &&
                          typeof fund.yearStartBalance === "number" &&
                          fund.balance - fund.yearStartBalance) > 0
                          ? classes.positive
                          : (typeof fund.balance === "number" &&
                              typeof fund.yearStartBalance === "number" &&
                              fund.balance - fund.yearStartBalance) < 0
                          ? classes.negative
                          : classes.zero
                      }
                    >
                      {typeof fund.balance === "number" &&
                      typeof fund.yearStartBalance === "number"
                        ? dollarize(fund.balance - fund.yearStartBalance)
                        : ""}
                    </Typography>
                  </Box>
                }
              />
            </ListItem> */}
          <ListItem>
            <ListItemText
              primary={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" className={classes.property}>
                    Investor Since
                  </Typography>
                  <Typography variant="body1">
                    {datestringize(fund.inceptionDate)}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}

PortfolioCard.propTypes = propTypes;
PortfolioCard.defaultProps = defaultProps;
