import React from "react";
import { Typography } from "@material-ui/core";

export default function Disclaimer(props) {
  return (
    <Typography
      variant="body1"
      color="textSecondary"
      style={{ fontSize: "0.8rem", textAlign: "center" }}
    >
      Disclosure: These estimated account values are for informational purposes
      only. The Fund accepts subscriptions and redemptions monthly per the terms
      of the Private Placement Memorandum (PPM) and Limited Partnership
      Agreement (LPA). Subscription and/or redemption transactions occur at the
      monthly Net Asset Value (NAV) calculated in the manner described in the
      PPM and LPA.
    </Typography>
  );
}
