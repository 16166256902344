import { getFromCastleAPI } from "../util/api-util";
import { RECEIVE_USER, RESET_DEFAULTS } from "../constants/actions";

export const getUser = (accessToken) => (dispatch) =>
  getFromCastleAPI("accounts/self", accessToken)
    .then((response) => dispatch(receiveUser(response.data.data)))
    .catch((error) => {
      console.log("[getUser] error: ", error);
      throw error;
    });

export const receiveUser = (data) => ({
  type: RECEIVE_USER,
  data,
});

export const resetDefaults = () => (dispatch) =>
  dispatch({ type: RESET_DEFAULTS });
