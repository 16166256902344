import {
  RESET_DEFAULTS,
  SET_SHOW_FUND_HOLDINGS_DISCLAIMER,
} from "../constants/actions";

const initialState = {
  isShowDisclaimer: false,
  userAcknowledged: false,
};

const fundHoldingsDisclaimerReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case SET_SHOW_FUND_HOLDINGS_DISCLAIMER:
      return {
        isShowDisclaimer: action.isShowDisclaimer,
        userAcknowledged: action.userAcknowledged,
      };
    case RESET_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default fundHoldingsDisclaimerReducer;
