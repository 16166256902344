import React, { useEffect } from "react";
import { oktaConfig } from "../okta";
import { useOktaAuth } from "@okta/okta-react";
import * as OktaSignIn from "@okta/okta-signin-widget";
// import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { useTheme } from "@material-ui/core";
import "../okta/okta.css";

const propTypes = {};

const defaultProps = {};

export default function OktaSignInWidget(props) {
  const theme = useTheme();
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    const { issuer, clientId, redirectUri, scopes } = oktaConfig;
    console.log(
      "[OktaSignInWidget] props ",
      issuer,
      clientId,
      redirectUri,
      scopes
    );
    const widget = new OktaSignIn({
      baseUrl: issuer.split("/oauth2")[0],
      brandName: "Castle",
      clientId,
      redirectUri,
      logo: "/brand/icon-navy.png",
      i18n: {
        en: {
          // Sign In
          "primaryauth.title": "Castle Funds",
          "primaryauth.username.placeholder": "Email Address",
          "primaryauth.username.tooltip": "Email Address",
          // Forgot Password
          "password.forgot.email.or.username.placeholder": "Email Address",
          "password.forgot.email.or.username.tooltip": "Email Address",
          "password.forgot.noFactorsEnabled":
            "No password reset options available. Please contact support@castlefunds.com.",
          // Reset Password
          "password.reset.title": "Reset your password",
          // Unlock Account
          "account.unlock.email.or.username.placeholder": "Email Address",
          "account.unlock.email.or.username.tooltip": "Email Address",
          "account.unlock.unlocked.desc":
            "You can log in using your existing email and password.",
          "account.unlock.noFactorsEnabled":
            "No unlock options available. Please contact support@castlefunds.com.",
          // Factors
          "factor.totpSoft.googleAuthenticator": "Authy/Google Authenticator",
          // Enroll Choices
          "enroll.choices.description":
            "Please secure your account with two-factor authentication",
          "enroll.choices.description.generic":
            "Please secure your account with two-factor authentication",
          "enroll.choices.description.specific":
            "Please secure your {0} account with two-factor authentication",
          // Contact Support
          "contact.support":
            "If you didn't provide a secondary email address or don't have access to email, please contact support@castlefunds.com",
          // Errors
          "error.username.required": "Please enter your email",
          "error.password.required": "Please enter your password",
          "error.auth.lockedOut":
            "Your account is locked. Please contact your support@castlefunds.com.",
          "errors.E0000119":
            "Your account is locked. Please contact your support@castlefunds.com.",
        },
      },
      colors: {
        brand: theme.palette.primary.main,
      },
      authParams: {
        // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
        issuer,
        scopes,
      },
      helpLinks: {
        custom: [
          {
            text: "Email us: support@castlefunds.com",
            href:
              "mailto:support@castlefunds.com?subject=Investor Portal Login Support",
          },
        ],
      },
    });
    widget.renderEl(
      { el: "#okta-sign-in-widget-container" },
      ({ tokens }) => {
        console.log("[OktaSignInWidget] resp tokens ", tokens);
        oktaAuth.handleLoginRedirect(tokens);
      },
      (err) => {
        throw err;
      }
    );

    return () => widget.remove();
  }, [oktaAuth, theme.palette.primary.main]);

  return (
    <div>
      <div id="okta-sign-in-widget-container" />
    </div>
  );
}

OktaSignInWidget.propTypes = propTypes;
OktaSignInWidget.defaultProps = defaultProps;