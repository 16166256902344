import React from "react";
import {
  Grid,
  Paper,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Portfolio from "./Portfolio";
import BalancesChart from "./BalancesChart";
import BalancesHeader from "./BalancesHeader";
import Performance from "./Performance";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  paper: {
    padding: 0,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreenAndLarger = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Grid container spacing={isLargeScreenAndLarger ? 3 : 0}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <BalancesHeader />
            {isLargeScreenAndLarger && <Performance />}
          </div>
          <BalancesChart />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Portfolio />
      </Grid>
    </Grid>
  );
}
