import axios from "axios";
import { getServerURL } from "./helpers";

export const getFromCastleAPI = (path, accessToken) =>
  axios.get(`${getServerURL()}/${path}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const postToCastleAPI = (path, data, accessToken) =>
  axios.post(`${getServerURL()}/${path}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const patchToCastleAPI = (path, data, accessToken) =>
  axios.patch(`${getServerURL()}/${path}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getFromCastleAPIWithUrl = (url, accessToken) =>
  axios.get(`${url}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
