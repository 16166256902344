import { getFromCastleAPI } from "../util/api-util";
import {
  RECEIVE_BALANCES,
  RECEIVE_HISTORICAL_BALANCES,
  SUM_HISTORICAL_BALANCES,
} from "../constants/actions";

export const getBalances = (userId, accessToken) => (dispatch) =>
  getFromCastleAPI(`balances/accounts/${userId}`, accessToken)
    .then((response) =>
      dispatch(receiveBalances(response.data.data.balances, userId))
    )
    .catch((error) => {
      console.log("[getBalances] error: ", error);
      throw error;
    });

export const receiveBalances = (data, userId) => ({
  type: RECEIVE_BALANCES,
  data,
  userId,
});

//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\\

export const getHistoricalBalances = (
  userId,
  fundId,
  from,
  to,
  accessToken
) => (dispatch) =>
  getFromCastleAPI(
    `balances/accounts/${userId}/history?fundId=${fundId}&from=${from}&to=${to}`,
    accessToken
  )
    .then((response) =>
      dispatch(receiveHistoricalBalances(fundId, response.data.data.balances))
    )
    .catch((error) => {
      console.log("[getHistoricalBalances] error: ", error);
      throw error;
    });

export const receiveHistoricalBalances = (fundId, data) => ({
  type: RECEIVE_HISTORICAL_BALANCES,
  fundId,
  data,
});

//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\\

export const sumHistoricalBalances = (historicalBalances) => (dispatch) => {
  const flattenedHistories = [].concat.apply(
    [],
    Object.values(historicalBalances)
  );
  const sumHistory = {};
  for (let i = 0; i < flattenedHistories.length; i++) {
    let snapshot = flattenedHistories[i];
    if (sumHistory[snapshot.timestamp]) {
      sumHistory[snapshot.timestamp] += snapshot.balance;
    } else {
      sumHistory[snapshot.timestamp] = snapshot.balance;
    }
  }
  return dispatch(summedHistoricalBalances(sumHistory));
};

export const summedHistoricalBalances = (data) => ({
  type: SUM_HISTORICAL_BALANCES,
  data,
});
