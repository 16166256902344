export const DRAWER_WIDTH = 240;
export const ERROR_SNACKBAR = {
  anchorOrigin: { vertical: "top", horizontal: "center" },
  autoHideDuration: 8000,
  variant: "error",
};
export const SUCCESS_SNACKBAR = {
  anchorOrigin: { vertical: "top", horizontal: "center" },
  autoHideDuration: 8000,
  variant: "success",
};
